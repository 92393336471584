/* Base */
@import "_includes/misc/_mixins.scss";
@import "_includes/_presets.scss";
@import "_includes/misc/_utils.scss";

/* Typography */
@import "_includes/typography/_typography.scss";
@import "_vendor/_fontawesome.css";

/* Vendor */
@import '_vendor/_flickity.css';
@import "~fullpage2.js/dist/jquery.fullpage.css";
@import '_vendor/_grecaptcha.css';

/* Structure */
@import '_includes/layout/_structure.scss';

/* Pages */
@import '_includes/pages/_home.scss';
@import '_includes/pages/_about.scss';
@import '_includes/pages/_ourTeam.scss';
@import '_includes/pages/_ourBlog.scss';
@import '_includes/pages/_ourClients.scss';
@import '_includes/pages/_education.scss';
@import '_includes/pages/_privacy.scss';

/* Elements */
@import '_includes/shared/_forms.scss';
@import '_includes/shared/_shared.scss';
@import '_includes/featured/_featured_blocks.scss';
@import '_includes/case-study/_case_study.scss';
@import '_includes/content-blocks/_content_blocks.scss';
@import '_includes/content-blocks/_graphs.scss';
@import '_includes/elements/_tags.scss';
@import '_includes/elements/_introSlider.scss';
@import '_includes/elements/_animatedTitle.scss';
@import '_includes/elements/_comments.scss';

@import '_includes/pages/_themes.scss';
