/* ==========================================================================
 Presets
 ========================================================================== */

$red: #f9423a;
$transparent-red: rgba(249,66,58,0.8);
$faded-red: #ff5d4a;
$pink: #fcaebb;
$faded-pink: #fad4db;
$black: #55565a;
$grey: #eeecec;
$light-gray: #f5f5f5;

$text-col: #ffffff;
$grey-text: #a7a6a8;
$bg-colour: $red;

$link-col: $text-col;
$highlight: $red;
$highlight-text: $black;

$contentBlock-vert-padding: 50px;
$reduced-contentBlock-vert-padding: 25px;

$gutter-padding: 40px;
$reduced-gutter-padding: 20px;
$increased-gutter-padding: 85px;
$max-width: 1280px + ($gutter-padding * 2);
$gutter-breakpoint: 705px;
$gutter-second-breakpoint: 1364px;

$mobile-breakpoint: 540px;

$first-breakpoint: 740px;
$second-breakpoint: 800px;

$menu-breakpoint: 914px;
$desktop-breakpoint: 1024px;

$bez: cubic-bezier(1,0,0,1);
