/* ==========================================================================
 About
 ========================================================================== */

.aboutServices-nav-nav{
    list-style: none;
    margin: 0px;
    width: 75%;
    li{
        display: inline-block;
        margin: 0px;
        a{
            text-decoration: none;
            color: $faded-pink;
        }
        a:hover,a.selected{
            color: #ffffff !important;
        }
    }
    .nav-separator{
        padding-left: 1px;
        padding-right: 1px;
        color: $faded-pink;
    }
}

.pageContentBlockSlides{
    margin-top: 30px;
}

@media (max-width: $mobile-breakpoint) {
    .pageContentBlockSlides{
        .serviceSubtitle{
            display: none;
        }
    }
    .page-about{
        .aboutServices-content .pageContentBlock{
            padding-top: 50px;
        }
        .formHolder{
            padding-top: 20px;
            .formHolder-header{
                margin-bottom: 20px;
            }
        }
    }
}
.fp-section{
    .fp-slidesNav.bottom{
        top: unset !important;
    }
}

.aboutTeam-content{
    .blogFeature{
        padding-top: 0px !important;
    }

    .teamGrid-block-link{
        img{
            mix-blend-mode: multiply;
        }
        &:hover{
            img{
                mix-blend-mode: unset;
            }
        }
        .teamGrid-block-details{
            .teamGrid-block-name,
            .teamGrid-block-role{
                color: #ffffff !important;
            }
        }
    }
}

.serviceCopySlides{
    width:100%;
    padding-bottom: 28px;
    .serviceCopySlide{
        width: 100%;
        @include opacity(0, true);
        &.is-selected{
            @include opacity(1, true);
        }

        .servicesLink{
            a{
                color: $faded-pink;
                text-decoration: none;
                @extend .subheader-size;
                &:hover{
                    color: #ffffff;
                }
            }
        }
        .servicesDescription{
            @extend .bodycopy-size;
            @extend .ff-mark;
        }
        .servicesSubtitle{
            @extend .subheader-size;
            color: $faded-pink;
            p{
                @extend .subheader-size;
                color: $faded-pink;
                margin-bottom: 10px;
            }
        }

        &.introSlide{
            .servicesDescription{
                @extend .small-header-size;
                @extend .ff-mark-bold;
                letter-spacing: -0.05em;
            }
        }
    }
}
.serviceCaseStudyGroups{
    .serviceCaseStudyGroup{
        display: none;
    }
}
.servicesIcons{
    width: 100%;
    pointer-events: none;
    //display: none;
    .flickity-slider{
        display: flex !important;
        align-items: center !important;
        transition: transform 0.6s ease !important;
    }
    .servicesIcon{
        width: 33%;
        @include responsive-ratio(16, 9);
        transition: width 0.6s linear;
        box-sizing: border-box;
        position: relative;
        img{
            width: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition: width 0.3s linear;
        }
        &.is-selected{
            img{
                transition: width 0.6s linear;
                width: 150%;
            }
        }
    }
}
.servicesAnimation{
    pointer-events: none;
    width: 80%;
    margin:auto;
    //@include responsive-ratio(16, 10);
    padding-top: 78% !important;
    max-width: 460px;
    position: relative;
    @media (max-width: $mobile-breakpoint) {
        padding-top: 87% !important;
    }
    .sAI {
        position: absolute;
    }
    .sAI-1{
        top:0%;
        left:14%;
        width: 30%;
    }
    .sAI-2{
        top: 15%;
        right: 0%;
        width: 25%;
    }
    .sAI-3{
        top:42%;
        left:0%;
        width: 33%;
    }
    .sAI-4{
        bottom:0%;
        right:0%;
        width: 45%;
    }
}

body:not(.fp-responsive){
    .pageContentBlock.aboutServicesNav{
        position:absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        z-index:2;
        @include opacity(0);
        pointer-events: none;
        transition: opacity 0.1s linear;
    }
    .aboutServices-content-new{
        .slide,.not-slide{
            .centreLock{
                padding-top: 220px;
                padding-bottom: 20px;
                @media (max-width: $mobile-breakpoint) {
                    padding-top: 120px;
                }
            }
        }
    }
    .aboutServices-casestudies .centreLock{
        padding-top: 220px;
        padding-bottom: 20px;
        @media (max-width: $mobile-breakpoint) {
            padding-top: 120px;
        }
    }
}
.aboutServicesNav{
    padding-bottom: 0px !important;
    @media (max-width: $mobile-breakpoint) {
        .mobileCollapseNav-content{
            padding-bottom: 20px;
        }
    }
    @media (min-width: $mobile-breakpoint) {
        background-color: transparent !important;
        .bg--pink{
            background-color: transparent !important;
        }
    }
}

.inline-services-nav{
    .aboutServicesNav{
        display: none !important;
    }
}
.fp-responsive{
    .aboutServicesNav{
        display: none;
    }
    .inline-services-nav{
        .aboutServicesNav{
            display: block !important;
        }
    }
}

%showServicesNav{
    transition-delay: 0.6s;
    @include opacity(1);
    pointer-events: auto;
}


.pageContent-about{
    .authorFeature{
        min-height: 1px !important;
    }
    .pageContentBlock {
        padding-bottom: 0px !important;
    }
}
