/* ==========================================================================
 Featured Blocks
 ========================================================================== */

.featuredBlock {
    position: relative;
    width: 100%;
    .featuredBlock-header {
        color: $pink;
        margin-bottom: 20px;
    }
    .featuredBlock-content {
        position: relative;
        .featuredBlock-col {
            display: inline-block;
            float: left;
            width: 100%;
            margin-bottom: 20px;
            .featuredBlock-linkBlock {}
        }
        .featuredBlock-col--first {}
        .featuredBlock-col--last {}
    }
}
.linkBlock-background {
    overflow: hidden;
}
.featuredBlock-linkBlock {
    position: relative;
    display: table;
    border-left: 1px dotted #ffffff;
    overflow: hidden;
    background-color: $red;
    width: 100%;
    article {}
    .featuredBlock-linkBlock-link {
        display: block;
        text-decoration: none;
        position: relative;
    }
    .linkBlock-background {
        pointer-events: none;
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 0;
        @extend .duotone;
        @include opacity(0);
        transition: opacity 0.4s;
        overflow: hidden;
        img{
            width: 100%;
            //width: calc(100% - 20px) !important;
        }
    }
    .linkBlock-quarters {
        pointer-events: none;
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .linkBlock-content,
    .tags {
        position: relative;
        display: block;
        z-index: 2;
    }
    .tags{
        position: absolute;
        pointer-events: none;
        padding:20px;
        bottom: 0px;
        left: 0px;
        .excessTagCount{
        }
    }
    .linkBlock-content {
        position: relative;
        padding: 20px 40px 20px 20px;
        .linkBlock-content-heading {
            position: absolute;
            top: 42px;
            left: 20px;
            width: calc(100% - 60px);
            line-height: 1.1em;
            margin: 0px;
            @include opacity(0);
        }
        .linkBlock-content-subheading {
            color: $pink;
            margin: 0px;
            line-height: 1.1em;
        }
        .linkBlock-content-copy {
            min-height: 50px;
            margin: 0px;
            //word-wrap: break-word;
            margin-bottom: 40px;
            margin-top: 11px;
        }
    }

    .linkArrow{
        position: absolute;
        bottom: 18px;
        left: -4px;
        display: none;
    }

    .featuredBlock-linkBlock-link:hover {

        .linkBlock-content {
            .linkBlock-content-heading {
                @include opacity(1);
            }
            .linkBlock-content-copy {
                @include opacity(0);
            }
        }
        .tags{
            @include opacity(0);
        }
        .linkArrow{
            display: block;
        }
        .linkBlock-background{
            @include opacity(0.6);
        }
    }
}

.touch .featuredBlock-linkBlock .featuredBlock-linkBlock-link{
    .linkBlock-content {
        .linkBlock-content-heading {
            @include opacity(1);
        }
        .linkBlock-content-copy {
            @include opacity(0);
        }
    }
}

.featuredBlock-linkBlock-blog{
    .linkBlock-content-heading{
        display: none;
    }
    .fake-header{
        display: block;
        color: #ffffff !important;
    }
}

.touch .featuredBlock-linkBlock {
    .linkBlock-background {
        @include opacity(0.6);
    }
}
@media (max-width: $first-breakpoint) {
    .featuredBlock-linkBlock {
        .linkBlock-background {
            @include opacity(0.6, true);
        }
    }
}

.featuredBlock-header-cols {
    width: 100%;
    clear: both;
    .featuredBlock-header-col {
        display: inline-block;
        float: left;
        width: 100%;
        margin-top: 40px;
        .featuredBlock-header {}
    }
}

.featuredBlock-footerLink {
    width: 100%;
    display: block;
    clear: both;
    .featuredBlock-footerLink-col {
        display: inline-block;
        float: left;
        width: 100%;
        margin-top: 40px;
        .featuredBlock-footerLink-link {
            color: $pink;
            text-decoration: none;
            .linkArrow {
                color: #ffffff;
            }
        }
    }
}
.page-homepage{
    .featuredBlock-footerLink-col a:hover{
        color: #ffffff;
    }
    .bg--grey .featuredBlock-footerLink-col a:hover{
        color: $black;
    }
    .bg--pink .featuredBlock-footerLink-col a:hover{
        color: #ffffff;
    }
}

@media (min-width: $first-breakpoint) {
    .featuredBlock {
        .featuredBlock-content {
            .featuredBlock-col {
                width: 33.333%;
            }
        }
    }
    .featuredBlock-footerLink {
        .featuredBlock-footerLink-col {
            width: 33.333%;
        }
    }
    .featuredBlock-header-cols {
        .featuredBlock-header-col {
            width: 33.333%;
        }
    }
}


.bg--grey, .bg--light-grey {
    //.featuredBlock {
        .featuredBlock-header {
            color: $grey-text;
        }
        .featuredBlock-content {
            color: $black;
        }
    //}
    .featuredBlock-linkBlock {
        color: $black;
        border-left: 1px dotted $grey-text;
        background-color: $grey;
        .linkBlock-content {
            .linkBlock-content-heading {
                color: $black;
            }
            .linkBlock-content-subheading {
                color: $grey-text;
            }
            .linkBlock-content-copy {
                color: $black;
            }
            .tags{
                .excessTagCount{
                    color: $grey-text;
                }
            }
        }
        .linkBlock-blogDetails{
            color: $grey-text;
        }
        .featuredBlock-linkBlock-link:hover {
            .linkBlock-content {
                .linkBlock-content-heading {
                    color: #ffffff;
                }
                .linkBlock-content-copy {
                    color: #ffffff;
                }
                .linkBlock-content-subheading {
                    color: #ffffff;
                }
            }
        }
    }
    .featuredBlock-footerLink {
        .featuredBlock-footerLink-col {
            .featuredBlock-footerLink-link {
                 color: $grey-text;
                .linkArrow {
                    color: $black;
                    polygon,path {
                        //stroke: $black;
                        fill: $black;
                    }
                }
                &:hover {
                  color: $black;
                }
            }
        }
    }
}
.bg--red{
    .blogFeature-header, .blogFeature-content .blogFeature-header{
        color: $pink !important;
    }
    .linkBlock-blogDetails{
        color: #ffffff !important;
        svg{
            circle,polygon,path,rect{
                fill: #ffffff;
            }
        }
    }
    .featuredBlock-footerLink {
        .featuredBlock-footerLink-col {
            .featuredBlock-footerLink-link {
                 color: $pink;
                .linkArrow {
                    color: #ffffff;
                    polygon,path {
                        fill: #ffffff;
                    }
                }
                &:hover {
                  color: #ffffff !important;
                }
            }
        }
    }
}
.bg--pink {
    //.featuredBlock {
        .featuredBlock-header {
            color: #ffffff;
        }
        .featuredBlock-content {
            color: #ffffff;
        }
    //}
    .blogFeature-header, .blogFeature-content .blogFeature-header{
        color: $faded-pink !important;
    }
    .featuredBlock-linkBlock {
        color: #ffffff;
        border-left: 1px dotted #ffffff;
        background-color: $pink;
        .linkBlock-content {
            .linkBlock-content-heading {
                color: #ffffff;
            }
            .linkBlock-content-subheading {
                color: #ffffff;
            }
            .linkBlock-content-copy {
                color: #ffffff;
            }
            .tags{
                .excessTagCount{
                    color: #ffffff;
                }
            }
        }
        .linkBlock-blogDetails{
            color: #ffffff;
        }
        .featuredBlock-linkBlock-link:hover {
            .linkBlock-content {
                .linkBlock-content-heading {
                    color: #ffffff;
                }
                .linkBlock-content-copy {
                    color: #ffffff;
                }
                .linkBlock-content-subheading {
                    color: #ffffff;
                }
            }
        }
    }
    .featuredBlock-footerLink {
        .featuredBlock-footerLink-col {
            .featuredBlock-footerLink-link {
                 color: $faded-pink;
                .linkArrow {
                    color: #ffffff;
                    polygon,path {
                        //stroke: $black;
                        fill: #ffffff;
                    }
                }
                &:hover {
                  color: #ffffff !important;
                }
            }
        }
    }

    .linkBlock-blogDetails{
        svg{
            circle,polygon,path,rect{
                fill: #ffffff;
            }
        }
    }
}

.bg--black {
    .featuredBlock {
        .featuredBlock-header {}
        .featuredBlock-content {}
    }
    .featuredBlock-linkBlock {
        background-color: $black;
        .linkBlock-content {
            .linkBlock-content-heading {}
            .linkBlock-content-subheading {
                color: $grey-text;
            }
            .linkBlock-content-copy {
                color: $grey-text;
            }
            .tags{
                .excessTagCount{
                    color: $grey-text;
                }
            }
        }
        .featuredBlock-linkBlock-link:hover {
            .linkBlock-content {
                .linkBlock-content-heading {
                    color: #ffffff;
                }
                .linkBlock-content-copy {
                    color: #ffffff;
                }
                .linkBlock-content-subheading {
                    color: #ffffff;
                }
            }
        }
    }
    .featuredBlock-footerLink {
        .featuredBlock-footerLink-col {
            .featuredBlock-footerLink-link {
                color: #ffffff;
                .linkArrow {
                    color: #ffffff;
                    path, polygon {
                        //stroke: #ffffff;
                        fill: #ffffff;
                    }
                }
            }
        }
    }
}

.bg--white {
    .featuredBlock {
        .featuredBlock-header {}
        .featuredBlock-content {}
    }
    .featuredBlock-linkBlock {
        background-color: #ffffff;
        border-left: 1px dotted $grey-text;
        .linkBlock-content {
            .linkBlock-content-heading {}
            .linkBlock-content-subheading {
                color: $grey-text;
            }
            .linkBlock-content-copy {
                color: $black;
            }
            .tags{
                .excessTagCount{
                    color: $grey-text;
                }
            }
        }
    }
    .featuredBlock-footerLink {
        .featuredBlock-footerLink-col {
            .featuredBlock-footerLink-link {
                color: $grey-text;
                .linkArrow {
                    color: $black;
                    path, polygon {
                        //stroke: $black;
                        fill: $black;
                    }
                }
                &:hover {
                    color: $black;
                }
            }
        }
    }
}

.linkBlock-background-cover {
    width: 102%;
    height: 102%;
    position: absolute;
    z-index: 2;
    &:before,
    &:after {
        content: " ";
        width: 50%;
        height: 50%;
        background: $red;
        position: absolute;
        transition: transform 0.25s ease;
    }
    &:after {
        left: 50%;
    }
    &:before {
        transform-origin: left top;
    }
    &:after {
        transform-origin: left top;
    }
}

.linkBlock-background-cover--2 {
    &:after,
    &:before {
        top: 50%;
    }
    &:before {
        transform-origin: left bottom;
    }
    &:after {
        transform-origin: right top;
    }
}

.featuredBlock-linkBlock-link:hover,
.touch .featuredBlock-linkBlock-link {
    .linkBlock-background-cover {
        &:before {
            transform: scaleX(0);
            transition-duration: 0.4s;
            //transition-delay: 0.35s;
        }
        &:after {
            transform: scaleY(0);
            //transition-duration: 0.5s;
            //transition-delay: 0.25s;
        }
    }
    .linkBlock-background-cover--2 {
        &:before {
            transform: scaleY(0);
            transition-duration: 0.3s;
            //transition-delay: 0.2s;
        }
        &:after {
            transform: scaleX(0);
            //transition-duration: 0.45s;
            //transition-delay: 0.1s;
        }
    }
}
.featuredBlock-linkBlock-link:hover{
    .linkBlock-blogDetails{
        @include opacity (0);
    }
}

.ourBlog, .blogPost, .bg--grey{
    .featuredBlock-linkBlock{
        background-color: $grey;
        .linkBlock-background-cover {
            &:before,
            &:after {
                background: $grey;
            }
        }
        .tags{
            .excessTagCount{
                color: $grey-text;
            }
        }
    }
}

.bg--pink{
    .featuredBlock-linkBlock{
        background-color: $pink;
        .linkBlock-background-cover {
            &:before,
            &:after {
                background: $pink;
            }
        }
        .tags{
            .excessTagCount{
                color: #ffffff;
            }
        }
    }
}

.bg--white{
    .linkBlock-background-cover {
        &:before,
        &:after {
            background: #ffffff !important;
        }
    }
}
.bg--black{
    .linkBlock-background-cover {
        &:before,
        &:after {
            background: $black !important;
        }
    }
}


.touch .blogFeature .featuredBlock-linkBlock .featuredBlock-linkBlock-link{
    .linkBlock-content {
        .linkBlock-content-heading {
            color: #ffffff;
        }
        .linkBlock-content-copy {
            color: #ffffff;
        }
        .linkBlock-content-subheading {
            color: #ffffff;
        }
    }
}

.touch{
    .featuredBlock-linkBlock{
        overflow: visible !important;
        //margin-bottom: 60px;
        .linkBlock-blogDetails{
            //bottom: inherit;
            //top: 100%;
            //top: calc(100% - 10px);
            bottom: 0px;
            @include opacity(1, true);
        }
    }
}

.featuredBlock-cols-mobile{
    display:none;
    .featuredBlock-linkBlock{
        border-left: none;
        border-top: 1px dotted #ffffff;
        .linkBlock-content{
            //min-height: 22vh !important;
            min-height: 195px !important;
        }
    }
    .flickity-page-dots{
        bottom: -50px;
    }
}
@media (min-width: $mobile-breakpoint) {
    .featuredBlock-cols-mobile .featuredBlock-col{
        width: 50% !important;
        width: calc(50% - 20px) !important;
        margin-right: 20px;
    }
}

.blogFeature .featuredBlock-cols-mobile{
    .linkBlock-content-copy,
    .linkBlock-content-subheading{
        color: #ffffff !important;
    }
    .linkBlock-content-heading.fake-header,
    .linkBlock-content-heading.real-header{
        display: none !important;
    }
    .featuredBlock-linkBlock .featuredBlock-linkBlock-link .linkBlock-content .linkBlock-content-copy{
        @include opacity(1);
    }
    .featuredBlock-linkBlock .featuredBlock-linkBlock-link .linkArrow{
        display: block !important;
    }
}

.linkBlock-blogDetails-el{
    white-space: nowrap;
}
.linkBlock-blogDetails-author{
    white-space: normal;
    min-height: 19px;
}

.bg--grey, .bg--white{
    .featuredBlock-cols-mobile .featuredBlock-linkBlock{
        border-top: 1px dotted $black;
    }
}
.bg--grey{
    .flickity-page-dots{
        .dot{
            background: $black;
        }
    }
}
.bg--pink{
    .featuredBlock-cols-mobile .featuredBlock-linkBlock{
        border-top: 1px dotted #ffffff;
    }
}

.flickity-page-dots{
    .dot{
        width: 5px !important;
        height: 5px !important;
    }
}

@media (max-width: $first-breakpoint) {
    .flickity-page-dots{
        .dot{
            //width: 5px !important;
            //height: 5px !important;
        }
    }
    .homeSection .homeSection-content{
        padding-bottom: 20px !important;
    }
    .featuredBlock-cols-mobile{
        display:block;
    }
    .featuredBlock-cols{
        display:none;
        .flickity-page-dots{
            position: fixed;
        }
    }
    .featuredBlock .featuredBlock-content .featuredBlock-col{
        margin-bottom: 10px !important;
    }
    .featuredBlock-footerLink{
        margin-top: 0px;
        .featuredBlock-footerLink-col{
            margin-top: 10px !important;
        }
        .featuredBlock-footerLink-col:last-child{
            display: none;
        }
        .featuredBlock-footerLink-col:first-child{
            display: block;
        }
    }
    .homeSection-subheader, .homeSection .homeSection-content .homeSection-header{
        margin-bottom: 15px !important;
        width: 100% !important;
        width: calc(100% - 30px) !important;
    }
    .featuredBlock-header-cols .featuredBlock-header-col{
        margin-top: 0px !important;
    }
    .featuredBlock-header{
        margin-bottom: 10px !important;
    }
    .featuredBlock-linkBlock {
        overflow: visible;
        padding-bottom:0px !important;
        .tags{
            display: none !important;
            bottom: -50px;
            @include opacity(1,true);
        }
    }
    .linkBlock-blogDetails{
        display: none !important;
        bottom: -50px;
        @include opacity(1,true);
        .columns{
            display: inline-block;
            float:left;
            width: 50%;
            .linkBlock-blogDetails-el:first-child{
                display: none;
            }
        }
    }
    .blogFooter{
        .featuredBlock{
            margin-top: 0px !important
        }
    }
}
@media (max-width: 992px) {
    .featuredBlock-linkBlock .featuredBlock-linkBlock-link{
        .linkArrow{
            //display: block !important;
        }
        .linkBlock-blogDetails{
            display: none !important;
        }
    }
    .touch .featuredBlock-linkBlock .linkArrow{
        display: block !important;
    }
}
@media (max-width: $mobile-breakpoint) {
    .homeSection .homeSection-content{
        padding-bottom: 40px !important;
    }
}

.introSlider-content{
    .featuredBlock-footerLink-col{
        display: block !important;
    }
}

.touch .featuredBlock-linkBlock .linkArrow{
    display: none !important;
}
