/* ==========================================================================
 Education
 ========================================================================== */

.education-nav{
    .centreLock-margin{
        padding-bottom: 20px;
        //border-bottom: 1px dotted #ffffff;
    }
    .education-nav-nav{
        list-style: none;
        margin: 0px;
        width: 75%;
        li{
            display: inline-block;
            margin-bottom: 2px;
            a{
                text-decoration: none;
                color: $grey-text;
            }
            a:hover,a.selected{
                color: #ffffff;
            }
        }
        h1{
            @extend .large-header-size;
        }
        .nav-separator{
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    .allSelected{
        li a{
            color: #ffffff;
        }
    }
}


.page-education{
    a{
        color: #ffffff;
    }
    .pageContentBlock-pageHeader{
        @include fp(font-size,40,60);
        line-height: 1.1em;
        letter-spacing: -0.05em;
    }
    .pageContentBlock{
        line-height: 1.2em;
        padding-top: 0px;
        display: block;
        .pageContentBlock-col--first{
            .pageContentBlock-text{
                p:first-child, p{
                    //@include fp(font-size,12,14);
                    color: #ffffff;
                }
            }
        }
    }
    .scrollSectionContent{
        padding-top: 80px;
    }
    .quote-holder{
        .quotation-mark{
            line-height: 0.6em;
        }
    }
    .quote-finish-holder{
        .quotation-mark{
            padding-right: 20px;
            margin-left: 90.33%;
        }
    }
}

.education-section{
    .contentBlock-quote-blockquote{
        margin-bottom: 0px;
        @media (max-width: 740px) {
            //padding-right: 50px;
        }
    }

    .module-holder{
        position: relative;
        .module-title, .educator-title{
            position: relative;
            @include fp(font-size,14,18);
            color: $grey-text;
            margin-top: 5px;
            h2{
                color: #ffffff;
            }
            .copyHolder{
                position: absolute;
                left: 0px;
                top: 220px;
                transform: rotate(-90deg) translateZ(0px);
                text-align: right;
                width: 220px;
                transform-origin: 0 0;
            }
        }
        .module-title{
        }
        .educator-title{
        }
        .portraitImage{
            margin-top: 10px;
            margin-bottom: 40px;
            width: 50%;
            filter: grayscale(100%);
            @include opacity(0.6);
            will-change: filter, opacity;
            transition: filter 0.2s ease, opacity 0.2s linear;
        }
        a{
            color:#ffffff;
            @extend .ff-mark;
        }
        a:hover{
            .portraitImage{
                filter: grayscale(0%);
                @include opacity(1);
                transition: filter 0.5s ease, opacity 0.2s linear;
            }
        }
    }
}

.educationForm-holder, .registration-details-holder{
    h2{
        color: $grey-text !important;
    }
}

.registration-details-holder{
    .datesBlock{
        position: relative;
        padding-left: 40px;

        .registrationIcon{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 21px;
            svg{
                width: 100%;
            }
        }
    }
    .registration-details{
        .registration-details-block{
            position: relative;
            padding-left: 40px;
            .registrationIcon{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 26px;
                svg{
                    width: 100%;
                }
            }
        }
    }
    h2{
        margin-bottom: 10px;
    }
    .dateBlock{
        p{
            @include fp(font-size,14,18);
            margin-bottom: 0px;
            .date{
                font-size: 12px;
                color: $grey-text;
            }
        }
    }
    .registration-details-block{
        .copy{
            margin-bottom: 0px;
            @extend .subheader-size;
        }
        .disclaimer{
            margin-top: 10px;
            font-size: 12px;
        }
    }
}

.educationForm-holder{
    .educationFormCopy{
        p{
            @extend .subheader-size;
        }
        .educationFormSubcopy{
            @extend .bodycopy-size;
            @extend .ff-mark;
            p{
                @extend .bodycopy-size;
                @extend .ff-mark;
            }
        }
    }
}

.module-video{
    .fluid-width-video-wrapper{
        padding-top: 56.1% !important;
    }
}

.mobileAuthorLink{
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 25px;
    text-decoration: none;
    border: none;
    margin-top: 8px;
    svg{
        width: 100%;
        path{
            fill: $grey;
        }
    }
}

.page-education .mobileSlides{
    display: none;
    margin-top: 10px;
    .mobileSlide{
        width: 100%;
        .registrationCopy{
            display: none;
        }
    }
}
.educationSection-header{
    margin-top: 1px !important;
}

@media (max-width: $first-breakpoint) {
    .education-section .module-holder .module-title .copyHolder, .education-section .module-holder .educator-title .copyHolder{
        position: relative;
        top:0px;
        width: 100%;
        transform: none;
        text-align: left;
    }
    .educator-details{
        display: none !important;
    }
    .educationSection-header{
        padding-bottom: 0px !important;
        margin-bottom: 1px !important;
        margin-top: 0px !important;
        width: calc(100% - 30px);
        margin-bottom: 20px;
    }
    .mobileAuthorLink{
        display: block;
    }

    .flickity-page-dots{
        bottom: 0px;
    }

    .page-education .desktopSlides{
        display:none !important;
    }
    .page-education .mobileSlides{
        display: block;
    }
    .education-section.registration-section{
        .fp-slidesNav{
            display: none;
        }
    }
    .page-education{
        .scrollSectionContent{
            padding-top: 20px;
        }
        .scrollSlides .section:first-child{
            .scrollSectionContent{
                padding-top: 60px;
            }
            .pageContentBlock{
                padding-bottom: 0px !important;
            }
        }
    }
    .educationForm{
        .six.columns{
            width: 48% !important;
        }
        .columns:last-child{
            margin-left: 4% !important;
        }
    }
    #fp-nav ul li a span, .fp-slidesNav ul li a span, #fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span{
        width: 5px !important;
        height: 5px !important;
        margin: -2px 0 0 -2px !important;
    }
}

.registration-section{
    .mobileSlides{
        .registration-details-holder{
            .registration-details,.registration-details-block{
                padding-left: 0px;
                .registrationMobileLink{
                    padding: 10px 0px;
                    border-bottom: 1px dotted $grey-text;
                    .registrationIcon{
                        position: relative;
                        display: inline-block;
                        float: left;
                    }
                }
                h2{
                    @extend .small-header-size2;
                    padding-left: 40px;
                    margin-top: 2px;
                }
                .registrationCopy{
                    margin: 10px 0px 0px 0px;
                    border-bottom: 1px dotted $grey-text;
                    padding-left: 50px !important;
                }
                .dateBlock{
                    //margin: 10px 0px 0px 10px;
                    padding-bottom: 10px;
                }
            }
            .registration-details-block{
                h2{
                    margin-top: 5px !important;
                }
            }
        }
        .flickity-page-dots{
            bottom: -30px;
        }
        .collapseListIcon{
            top:1.4em;
        }
        .open .collapseListIcon{
            .top,.bottom{
                display: none !important;
            }
        }
        .flickity-viewport{
            //min-height: 1000px;
        }
    }
}

@media (max-width: $mobile-breakpoint){
    .quote-holder{
        .quotation-mark{
            //position: absolute;
            //top: 17px;
            //left: 0px;
        }
        blockquote{
            //margin-top: 45px !important;
        }
    }
    .testimonial-section .contentBlock-quote-blockquote cite{
        //padding-top: 20px !important;
    }
}
