/* ==========================================================================
 Structure
 ========================================================================== */

body {
  @extend .body-reg;
  background-color: $bg-colour;
  transition: background-color 0.4s linear;
}
.no-scroll{
  overflow-y:scroll;
}
.no-scroll body{
  overflow: hidden !important;
}

figure {
  margin-bottom: 17px;
}

.image-link {
  border: none !important;
}

.main img {
  max-width: 100%;
}


/* ==========================================================================
 Layout and styling
 ========================================================================== */
.page-loaded .main,
.page-loaded .footer {
  @include opacity(1);
  pointer-events: auto;
}
.pjax-loading .main,
.pjax-loading .footer{
  @include opacity(1);
  pointer-events: none;
}
.main-container{
}

.touch .main{
  overflow-x: hidden;
}

.touch.safari .main, .page-blogs main{
    overflow-x: unset;
}

/* ==========================================================================
 Background Colours
 ========================================================================== */

.bg--red, .page-privacy{
    background-color: $red;
    color: #ffffff;
    p,h1,h2,h3,h4,h5,h6,a{
        color: #ffffff;
    }
}
.bg--pink{
    background-color: $pink;
    color: #ffffff;
    p,h1,h2,h3,h4,h5,h6,a{
        color: #ffffff;
    }
}
.bg--white{
    background-color: #ffffff;
    color: $black;
    p,h1,h2,h3,h4,h5,h6,a{
        color: $black;
    }
}
.bg--black{
    background-color: $black;
    color: #ffffff;
    p,h1,h2,h3,h4,h5,h6,a{
        color: #ffffff;
    }
}
.bg--grey{
    background-color: $grey;
    color: $black;
    p,h1,h2,h3,h4,h5,h6,a{
        color: $black;
    }
}
.bg--light-grey{
    background-color: $light-gray;
    color: $black;
    p,h1,h2,h3,h4,h5,h6,a{
        color: $black;
    }
}

.colourOverlay{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: z('overlay-block');
    background-color: $red;
    @include opacity(0);
    pointer-events: none;
    transition: opacity 0.3s linear;
    transition-delay: 0.1s;
}
.menuHovering{
    .colourOverlay{
        transition-delay: 0s;
        @include opacity(0.9);
    }
    &.blogSearchHovering{
        .colourOverlay{
            @include opacity(0,true);
        }
    }
}

.page-caseStudy, .page-ourBlog, .page-ourBlogList{
    background-color: #ffffff;
    a{
        color: $black;
    }
}
.redactor-styles, .content-blocks {
    a {
        color: $pink;
        text-decoration: none;

        &:hover {
            color: $red;
        }
    }
    mark {
        color: $red;
        background: transparent;
        padding: 0;
    }
}
.bg--red .content-blocks mark{
    color: $pink;
}
.bg--black .content-blocks mark{
    color: $pink;
}

.page-ourTeam, .page-ourTeamList, .page-ourClients, .page-education{
    background-color: $black;
    .colourOverlay{
        background-color: $black;
    }

    .pageContentBlock-link {
        color: $grey-text !important;
        &:hover {
            color: #fff !important;
        }
    }
}

.page-themeList, .page-themeAllList{
    background-color: $pink;
    .colourOverlay{
        background-color: $pink;
    }
}


.pjax-loading body{
    //background-color: $red !important;
}



/* ==========================================================================
 Viewport Scrolling
 ========================================================================== */

.iScrollVerticalScrollbar{
    width: 5px !important;
}
.fp-responsive{
    //remove srolloverflow from sections on mobile
    .iScrollVerticalScrollbar{
        display: none !important;
    }
    .fp-scrollable{
        height: auto !important;
        .fp-scroller{
            overflow: shown !important;
            transform: translate(0px, 0px) !important;
        }
    }
}
.no-touch .section.fp-section .fp-scrollable .iScrollVerticalScrollbar{
    @include opacity(0);
    transition: opacity 0.2s linear;
}
.no-touch .section.fp-section.active .fp-scrollable .iScrollVerticalScrollbar{
    @include opacity(1);
    transition: opacity 0.4s linear;
}

/* ==========================================================================
 Footer
 ========================================================================== */

.footer-container{
    background: #ffffff;
    color: $grey-text !important;
    width: 100%;
    display: table;
    p,h1,h2,h3,h4,h5,h6 {
        color: $grey-text !important;
    }
    a {
        color: $grey-text !important;

        &:hover {
            color: $black !important;
        }
    }
    .footer{
        display: table-cell;
        vertical-align: middle;
        padding: 60px 0px 40px 0px;
    }
    .footerCols{
        h4{
            margin: 0px;
        }
        .footerCols-signup,
        .footerCols-links-siteLinks,
        .footerCols-links-socialLinks,
        .footerCols-contact{
            padding-bottom: 40px;
            a{
                word-break: break-all;
            }
        }
        .footerCols-links-siteLinks{
            a{
                text-decoration: none;
            }
        }
        ul{
            list-style: none;
            margin: 0px;
            li{
                margin: 0px;
            }
        }

        .footerCols-terms,
        .footerCols-copyright,
        .footerCols-credit{
            p{
                margin: 0px;
            }
        }
    }
}

@media (max-width: $first-breakpoint) {
    .footerCols-signup,
    .footerCols-links-siteLinks,
    .footerCols-links-socialLinks,
    .footerCols-contact{
        padding-bottom: 20px !important;
    }
}

.podcastOverlayOpen{
    .footer-container{
        padding-bottom: 170px;
    }
}

//.fp-enabled{
    .footer-container{
        min-height: 50vh;
    }
//}
.touch.no-fp{
    .footer-container{
        min-height: 100vh;
    }
}

.fp-slidesNav{
    top: 80%;
    top: calc(100vh - 150px);
    text-align: center;
}
@media (max-width: $mobile-breakpoint) {
    .fp-slidesNav{
        bottom: 20px !important;
        top: initial;
        top: unset;
    }
}
#fp-nav ul li a, .fp-slidesNav ul li a{
    padding: 10px;
}
#fp-nav ul li a span, .fp-slidesNav ul li a span,
#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span{
    background-color: $grey-text;
    width: 8px !important;
    height: 8px !important;
    margin: -4px 0 0 -4px !important;
}
#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span{
    background-color: #ffffff !important;
}
.fp-controlArrow{
    display: none !important;
}

/* ==========================================================================
 Revealing Elements
 ========================================================================== */

.reveal{
    @include opacity(0);
    opacity: 0 !important;
    transition: opacity 0.4s ease;
}
.no-js .reveal, .revealed{
    @include opacity(1);
    opacity: 1 !important;
}
