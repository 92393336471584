/* ==========================================================================
 Tags
 ========================================================================== */
.tags{
    display: block;
    .tags-content{
        display: block;
    }
}

.tagsList{
    color: #ffffff;
    pointer-events: none;
    .tagsList-item{
        display: inline;
        .tagsList-link{
            color: #ffffff;
            pointer-events: auto;
            color: #ffffff;
            text-decoration: none;
        }
    }
    .tagsList-separator{
        display: inline;
    }
}

.bg--grey{
    .tags{
        color: $grey-text;
    }
    .tagsList{
        .tagsList-item{
            .tagsList-link{
                color: $grey-text;
            }
        }
        .tagsList-separator{
            color: $grey-text;
        }
    }
}
