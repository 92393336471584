/* ==========================================================================
 Case Study Grid
 ========================================================================== */
.caseStudyGrid{
    padding-top: 75px;
    padding-bottom: 20px;
    .featuredBlock-caseStudyGrid{
        margin-bottom: 40px;
    }
    .caseStudyGrid-content{
        min-height: 58vh;
    }
    .featuredBlock-col{
        display: inline-block;
        float: left;
        width:100%;
    }
}
.caseStudyList-nav{
    .centreLock-margin{
        padding-bottom: 20px;
        border-bottom: 1px dotted #ffffff;
    }
    .caseStudyList-nav-header{
        margin-bottom: 20px;
    }
    .caseStudyList-nav-nav{
        //display: none;
        list-style: none;
        margin: 0px;
        width: 75%;
        li{
            display: inline-block;
            margin: 0px;
            a{
                text-decoration: none;
                color: $pink;
            }
            a:hover,a.selected{
                color: #ffffff;
            }
        }
        .nav-separator{
            padding-left: 1px;
            padding-right: 1px;
        }
    }
    .catSelected{
        .nav-separator{
            color: $pink;
        }
    }
    .allSelected{
        li a{
            color: #ffffff;
        }
    }
}

.caseStudyList-intro-header{
    color: $pink;
    margin-top: 10px;
    margin-bottom: 10px;
}
.caseStudyList-intro-copy{
    p:last-child{
        margin-bottom: 0px;
    }
}

@media (max-width: $first-breakpoint) {
    .caseStudyGrid{
        margin-top: 30px !important;
        .featuredBlock-col{
            width: 33.333%;
        }
    }
}
@media (min-width: $mobile-breakpoint) and (max-width: $menu-breakpoint) {
    .caseStudyGrid-content{
        .featuredBlock-col{
            width: 50% !important;
        }
    }
}
@media (max-width: $menu-breakpoint) {
    .caseStudyList-nav .centreLock-margin{
        padding-bottom: 0px !important;
    }
}

/* ==========================================================================
 Case Study Entry
 ========================================================================== */

.caseStudyHeader-text-tags{
    width: 80%;
}
