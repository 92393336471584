/* ==========================================================================
 Graphs
 ========================================================================== */

 .graphContainer {
    pointer-events: none;
    position: relative;
    background-color: transparent;
 }

.piecharts{
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    .piechart{
        text-align: center;
        display: inline-block;
        width: 30%;
    }
}
.contentBlock-graph-piechart .contentBlockContent{
    //min-height: 90vh;
}

@media (max-width: $menu-breakpoint){
    .piecharts{
        .piechart{
            position: absolute;
            width:60%;
            margin:0px !important;
        }
        .piechart:first-child{
            top:0px;
            right:0px;
        }
        .piechart:nth-child(2){
            bottom:5px;
            left:0px;
        }
        .piechart:nth-child(n + 3){
            display: none;
        }
    }
}
.contentBlock-section-col{
    .piecharts{
        .piechart{
            position: absolute;
            width:65%;
            margin:0px !important;
            max-width: 395px;
            min-width: 250px;
        }
        .piechart:first-child{
            top:20px;
            left:46%;
            right: unset;
        }
        .piechart:nth-child(2){
            bottom:15px;
            right:46%;
            left: unset;
        }
        .piechart:nth-child(n + 3){
            display: none;
        }
    }
}
@media (max-width: $menu-breakpoint){
    .contentBlock-section-col{
        .piecharts{
            .piechart:first-child{
                top:20px;
                right:-60px;
                left: unset;
            }
            .piechart:nth-child(2){
                bottom:15px;
                left:-60px;
                right: unset;
            }
        }
    }
}
.contentBlock-section--1-2 .contentBlock-section-col--first .contentBlock-graph-piechart .piecharts,
.contentBlock-section--2-1 .contentBlock-section-col--last .contentBlock-graph-piechart .piecharts{
    .piechart:first-child{
        top:40px;
    }
    .piechart:nth-child(2){
        bottom:40px;
    }
}


.pieChart-section{
    .piecharts .piechart{
        position: relative;
        display: inline-block;
        width: 30%;
        top:unset;
        bottom:unset;
        left:unset;
        right:unset;
    }
}
@media (max-width: $first-breakpoint){
    .piecharts{
        .piechart{
            position: relative !important;
            width:100% !important;
            margin:0px !important;
            display: inline-block !important;
            top:unset !important;
            bottom:unset !important;
            left:unset !important;
            right:unset !important;
        }
    }
}

.graphFooter{
    position: absolute;
    bottom: 20px;
    left: 0px;
    width: 100%;
    pointer-events: none;
    .graphKey{
        .graphKey-item{
            display: inline;
            padding-right: 20px;
            span{
                display: inline;
            }
            .arch-small-svg{
                padding-right: 5px;
                display: inline-block;
                width: 25px;
                height: 25px;
                position: relative;
                top: 5px;
                path{
                    fill: #ffffff !important;
                }
            }
        }
    }
}

.contentBlock-graph{
    min-height: 100vh;
    .centreLock{
        //height: 100%;
        .centreLock-margin{
            //height: 100%;
        }
    }
    .graphHeader{
        position: absolute;
        top: 30px;
        left: 0px;
        @extend .ff-mark-bold;
        @extend .small-header-size;
    }
}
.contentBlock-section-cb .contentBlock-graph .graphHeader{
    @extend .subheader-size;
    top: 42px;
}
.vp-short{
    .contentBlock-graph .graphHeader{
        @extend .subheader-size;
        top: 30px;
    }
}

.contentBlock-graph-barChart{
    .desktopGraph{
        svg{
            width: 100%;
        }
    }
    .graphKey{
        .graphKey-item{
            span{
                @include opacity(1, true);
            }
        }
        .graphKey-item:nth-child(n+3){
            display: none;
        }
        .graphKey-item:first-child{
            .arch-small-svg path{
                fill: #FFBEC8 !important;
            }
        }
        .graphKey-item:last-child{
            .arch-small-svg path{
                fill: #FFFFFF !important;
            }
        }
    }
}

.contentBlock-graph-arches{
    .mobileGraph{
        .arches-svg{
            width: auto;
            height: 100vh;
            transition: transform 0.7s ease;
        }
    }
    .mobileGraph.chartScrolled{
        .arches-svg{
            transform: translateX(-50%);
        }
    }
}
.bars-mobile{
    width: 100%;
}

.desktopGraph{
    pointer-events: none;
}
.smallDesktopGraph,
.mobileGraph{
    display: none;
}
.ie{
    .arches-svg, .bars-svg{
        width: 100%;
        height: 100%;
    }
}

@media (max-width: $menu-breakpoint){
    .desktopGraph{
        display: none;
    }
    .smallDesktopGraph{
        display: block;
    }
    .mobileGraph{
        display: none;
    }
}
.contentBlock-section-col{
    .desktopGraph{
        display: none;
    }
    .smallDesktopGraph{
        display: block;
    }
    .mobileGraph{
        display: none;
    }
}
@media (max-width: $mobile-breakpoint){
    .desktopGraph{
        display: none;
    }
    .smallDesktopGraph{
        display: none;
    }
    .mobileGraph{
        display: block;
    }
    .graphFooter .graphKey .graphKey-item{
        display: block;
        clear: both;
    }
    .contentBlock-graph .graphHeader{
        top: 20px;
    }
}


.graphSlider{
    width: 100%;
    padding-bottom: 20px;
    .flickity-viewport{
        overflow: visible;
    }
    .graphSlider-slide{
        width: 100%;
    }
}
