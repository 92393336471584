/* ==========================================================================
 Our Blog
 ========================================================================== */
.blogFeature{
    position: relative;
    padding-top:40px;
    padding-bottom:40px;
    box-sizing: border-box;
    min-height: 50vh;
    @media (max-width: $mobile-breakpoint) {
        min-height: 90vh;
    }
    .blogFeature-header{
        color: $grey-text !important;
    }
}

.blogAuthors {
    padding-top:40px;
    padding-bottom:40px;
    box-sizing: border-box;

    .blogFeature-header{
        color: $grey-text !important;
    }
}

.blog-icon{
    display: inline-block;
    width: 16px;
    padding-right: 10px;
    svg{
        width: 100%;
        rect, circle, path, polygon{
            fill: $grey-text;
        }
    }
}

.blogLinkBlock{
    border-left: 1px dotted $black !important;
    a{
        color: $black;
    }
    .linkBlock-content {
        position: relative;
        .linkBlock-content-heading {
            position: relative !important;
            margin: 0px !important;
            top: 0px !important;
            line-height: 1.1em;
            padding-bottom: 20px;
        }
        .linkBlock-content-subheading {
            margin: 0px !important;
        }
        .linkBlock-content-copy {
            @include opacity(0);
            position: absolute !important;
            top: 20px;
            left: 0px;
            color: #ffffff !important;
        }
    }
    .blogLinkBlock-details{
        display: block;
        color: $grey-text;
        pointer-events: none;
        z-index: 10;
        .blogLinkBlock-details-content{
            display: block;
            .blogpost-details{
                display: block;
                .blogpost-details-col{
                    width: 50%;
                    display: inline-block;
                    .blogpost-details-row{
                        width: 100%;
                        display:block;
                    }
                }
                .blogpost-details-col--first{
                    float: left;
                }
                .blogpost-details-col--last{
                    float: left;

                }
            }
        }
    }
    .linkArrow{
        display: none;
        position: absolute;
        left: 5px;
        bottom: 10px;
        margin: 0px;
        padding:0px;
    }
    a:hover{
        .blogLinkBlock-details{
            @include opacity(0);
        }
        .linkBlock-content-subheading {
            color: #ffffff !important;
        }
        .linkArrow{
            display: block;
        }
    }

    .featuredBlock-footerLink-link{
        color: $black !important;
    }
}


.blogAuthorBlock{
    padding-bottom: 20px;
    .blogAuthor-holder{
        width: 100%;
        .blogAuthor-slide{
            width: 100%;
            display: inline-block;
            float: left;
            img{
                width: 100%;
            }
            .blogAuthor-copy{
                color: $grey;
                padding:10px 0px 10px 0px;
                a{
                    color: $grey;
                    //text-decoration: none;
                }
            }
        }
    }
    .flickity-page-dots{
        bottom: -25px;
    }
}

.blogRemainsHolder .blogRemains-more{
    display: none;
}
.blogRemains-moreLink{
    margin-top:40px;
    margin-bottom:40px;
    @extend .subheader-size;
    line-height: 1.1em;
    a{
        color: $grey-text;
        text-decoration: none;
        &:hover{
            color: $black;
        }
    }
    .linkArrow{
        svg polygon{
            fill: $black;
        }
    }
}
.blogRemainsHolder.open{
    .blogRemains-moreLink{
        display: none !important;
    }
    .blogRemains-more{
        display: block !important;
    }
}

@media (max-width: $mobile-breakpoint) {
    .blogAuthorBlock .flickity-page-dots .dot{
        //margin: 0 2px;
    }
    .blogAuthorBlock .blogAuthor-copy{
        display: none;
    }
}

@media (min-width: $mobile-breakpoint) {
    .blogAuthorBlock{
        .blogAuthor-holder{
            width: calc(100% + 20px);
            margin-left: -10px;
            .blogAuthor-slide{
                width: 50%;
                width: calc(50% - 20px);
                margin-right: 20px;
            }
        }
    }
}

@media (min-width: $menu-breakpoint) {
    .blogAuthorBlock{
        .blogAuthor-holder{
            width: calc(100% + 20px);
            margin-left: -10px;
            .blogAuthor-slide{
                width: 25%;
                width: calc(25% - 20px);
                margin-right:20px;
            }
        }
    }
}


/* ==========================================================================
 Blog Entry
 ========================================================================== */
.pageContent-ourBlog{
    color: $black;

    .contentBlock{
        padding-bottom: 20px !important;
        .contentBlockContent{
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
    }

    p,h1,h2,h3,h4,h5,h6{
        color: $black;
    }
    .blogArticle{
        padding-bottom: 30px;

        .contentBlock-quote .quote-holder{
            width: 100% !important;
        }
        .contentBlock-header .contentBlockContent{
            width: 100% !important;
        }

        .contentBlock ol, .contentBlock ul{
            color: $black !important;
        }

        @media (max-width: $first-breakpoint - 1) {
            .row {
                .aside {
                    .blogPost-details {
                        display: none;
                    }
                }
            }
        }
        @media (min-width: $first-breakpoint) {
            .row {
                .blogPost {
                    .blogPost-details {
                        display: none;
                    }
                }
            }
        }
    }

    .blogPost-details {
        display: inline-block;
        .blogPost-date,
        .blogPost-comments,
        .blogPost-tags,
        .blogPost-back,
        .blogPost-social{
            position: relative;
            display: inline-block;
            float: left;
            clear: both;
            text-align: left;
            padding-bottom: 20px;
            .aside-icon{
                display: block;
                position: absolute;
                top: 0px;
                left: -40px;
                @media (max-width: $mobile-breakpoint) {
                    padding-bottom: 40px;
                }
                width: 20px;
                height: 20px;
                padding-right: 0px;
                svg{
                    width: 100%;
                    rect, circle, path, polygon{
                        fill: $black;
                    }
                }
            }
        }

        .blogPost-back{
            svg.link-arrow-svg {
                transform: rotate(180deg);
            }
            a {
                text-decoration: none;
                cursor: pointer;
            }
            @media (max-width: $mobile-breakpoint) {
                padding-bottom: 40px;
            }
        }
        .blogPost-tags{
            ul{
                list-style: none;
                margin: 0px;
                li{
                    margin: 0px;
                    a{
                        text-decoration: none;
                    }
                }
            }
            @media (max-width: $mobile-breakpoint) {
                padding-bottom: 0px;
            }
        }
        .blogPost-social{
            font-size: 20px !important;
            .socialIconLink, .socialIconLink i{
                color: $black;
            }

            .socialLink-holder {
                margin-right: 20px;
                @media (max-width: $mobile-breakpoint) {
                    margin-right: 15px !important;
                }
            }
        }
    }

    .blogPost{
        position: relative;
        .blogPost-title{
            margin-bottom: 10px;
        }
        .blogPost-authors{
            font-size: 18px;

            //&:hover {
                .blogPost-author {
                    color: $grey-text;
                    //@include opacity(0.7);
                }
            //}
        }
        .blogPost-author{
            text-decoration: none;
            margin-bottom: 20px;
            display: inline-block;

            &:hover {
                color: $black;
                //@include opacity(1, 1);
            }
        }
        .contentBlock{
            background: none !important;
            padding-top: 0px;
            padding-bottom: 40px;
            @extend .largecopy-size;
            p{
                @extend .largecopy-size;
            }
            .contentBlock-copy-title{
                @extend .mediumsubheader-size;
            }
        }
        .centreLock{
            max-width: 6666px;
            .centreLock-margin{
                margin: 0px;
            }
        }

        .blogPost-details {
            display: block;
            @extend .ff-mark;

            .blogPost-date,
            .blogPost-comments,
            .blogPost-tags,
            .blogPost-back,
            .blogPost-social {
                clear: none;
                float: left;
                width: calc(50% - 40px);
                padding-left: 40px;

                .aside-icon {
                    left: 0;
                }
            }
            .blogPost-social {
                display: none;
            }
        }
    }
    .aside{
        text-align: center;
        font-size: 12px;
    }
}
@media (min-width: $first-breakpoint) {
    .no-fp{
        .pageContent-ourBlog{
            .aside{
                position: relative;
                position: -webkit-sticky;
                position: sticky;
                top: 210px;
            }
        }
    }
}

.bookRecommendations-header{
    color: $grey-text;
}

.podcastLink{
    position: relative;
    display: block;
    .playIcon{
        position: absolute;
        pointer-events: none;
        left: 50%;
        top: 50%;
        z-index: 2;
        transform: translate(-50%,-50%);
        @include opacity(0);
        transition: opacity 0.2s linear;
    }
}
.podcastLink:hover{
    .playIcon{
        @include opacity(1);
    }
}

.doNextLinks{
    padding-top: 20px;
    padding-bottom: 20px;
    //min-height: 50vh;
    .doNextLinks-link{
        border: 2px solid #eeecec;
        border-radius: 26px;
        padding: 7px 15px 10px 15px;
        margin: 10px;
        font-size: 18px;
        text-decoration: none;
        background-color: #ffffff;
        display: inline-block;
        float: left;
        transition: border-color 0.4s;
    }
    .doNextLinks-link:hover{
        border-color: $black;
    }
}

.pageContent-ourBlog{
    .blogArticle{
        .bg--pink,.bg--black,.bg--red{
            .contentBlock ol, .contentBlock ul{
                color: #ffffff !important;
            }
        }
        .content-blocks.bg--pink a{
            color: $faded-pink !important;
            &:hover{
                color: #ffffff !important;
            }
        }
    }
}

.bg--pink{
    .doNextLinks{
        .doNextLinks-link{
            background-color: $pink;
            border-color: #ffffff;
            color: #ffffff;
        }
        .doNextLinks-link:hover{
            border-color: $faded-pink;
            color: $faded-pink;
        }
    }
}
.bg--red{
    .doNextLinks{
        .doNextLinks-link{
            background-color: $red;
            border-color: #ffffff;
            color: #ffffff;
        }
        .doNextLinks-link:hover{
            border-color: $pink;
            color: $pink;
        }
    }
}
.bg--black{
    .doNextLinks{
        .doNextLinks-link{
            background-color: $black;
            border-color: #ffffff;
            color: #ffffff;
        }
        .doNextLinks-link:hover{
            border-color: $grey-text;
            color: $grey-text;
        }
    }
}


.blogFooter{
    .moreFromBlock{
        min-height: 82vh;
    }
}

.linkBlock-blogDetails{
    pointer-events: none;
    display: block;
    position: absolute;
    z-index: 2;
    padding: 20px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    .linkBlock-blogDetails-el{
        padding-top: 5px;
        margin-left: 30px;

        .aside-icon {
            margin-left: -30px;
        }
    }
}


.contentBlock-imported{
    table {
        border-bottom: 1px solid #ddd;
        margin: 0 0 1.625em;
        width: 100%;
        text-align:left;
    }

    th {
        color: #666;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.1em;
        line-height: 2.6em;
    }

    td {
        border-top: 1px solid #ddd;
        padding: 6px 10px 6px 0;
    }
    h1,h2,h3,h4,h5,h6{
        margin-top: 40px;
        font-weight: bold;
    }
    p{
        margin-bottom: 15px;
    }
    p,ol,ul,img{
        margin-top:20px;
    }

    img{
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .alignleft, .alignright {
        display: inline;
        width: 25%;
        margin-top: 0px;
        margin-bottom: 0px;
        display: block;
        clear: both;
    }
    .alignleft{
        float: left;
        margin-right: 1.25em;
    }
    .alignright {
        float: right;
        margin-left: 1.25em;
    }

    .alignnone,
    .aligncenter {
        clear: both;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    img.alignleft,
    img.alignright,
    img.aligncenter,
    img.alignnone{
        margin-bottom: 1.25em;
    }

    p img,
    .wp-caption {
        margin-top: 0.4em;
    }
    .wp-caption {
        margin-bottom: 1.25em;
        max-width: 96%;
        text-align:center;
    }

    .wp-caption img {
        display: block;
        margin: 0 auto;
        max-width: 98%;
    }

    p.wp-caption-text{
        margin-bottom: 0;
    }

    .wp-caption .wp-caption-text,
    .gallery-caption {
        color: #666;
        font-size: 12px;
    }
}

.podcastEmbedHolder{
    margin-top: 20px;
    width: 100% !important;
    max-width: 100% !important;
}

.blogRemainsHolder{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 80px;
    .blogRemains{
        width: 100%;
        .blogRemainsBlock{
            display: inline-block;
            float: left;
            width: 100%;
            padding-top: 20px;
            padding-bottom: 2px;
            border-bottom: 1px dotted $grey-text;
            .blogRemainsBlock-type{
                color: $grey-text;
                display: block;
                @extend .smallcopy-size;
                color: $grey-text;
                padding-bottom: 12px;
            }
            .blogRemainsBlock-link{
                color: $black;
                display: block;
                font-size: 24px;
                line-height: 1em !important;
                text-decoration: none;
                border: none;
            }
        }
    }
}
.bg--pink{
    .blogRemains{
        .blogRemainsBlock-type{
            color: $faded-pink !important;
        }
        .blogRemainsBlock-link{
            color: #ffffff !important;
        }
        .blogRemainsBlock{
            border-color: #ffffff !important;
        }
        .js-blogRemains-moreLink{
            color: $faded-pink !important;
            &:hover{
                color: #ffffff !important;
            }
        }
    }
    .blogRemains-moreLink a{
        color: $faded-pink !important;
        &:hover{
            color: #ffffff !important;
        }
    }
    .blogRemains-moreLink .linkArrow svg polygon{
        fill: #ffffff !important;
    }
}


@media (min-width: $second-breakpoint) {
    .blogRemainsHolder{
        .blogRemains{
            .blogRemainsBlock{
                width: 50%;
                width: calc(50% - 62px);
                margin-right: 124px;
                .blogRemainsBlock-type{
                }
                .blogRemainsBlock-link{
                }
            }
            .blogRemainsBlock:nth-child(even){
                margin-right: 0px !important;
            }
        }
    }
}

@media (max-width: $mobile-breakpoint) {
    .doNextLinks-header{
        margin-bottom: 0px !important;
    }
    .doNextLinks .doNextLinks-link{
        padding: 2px 10px 4px 10px !important;
        margin: 10px 10px 0px 0px !important;
        font-size: 16px !important;
    }
    .asideTags-tag-link.js-blogGoBack{
        display: none;
    }
}

.asideTags.hover{
    .asideTags-tag-link{
        @include opacity(0.7);
    }
}
.asideTags{
    .asideTags-tag-link:hover{
        @include opacity(1,true);
    }
}
.blogPost-back .aside-icon{
    transition: left 0.4s ease;
}
.blogPost-back:hover{
    .aside-icon{
        left: -45px !important;
    }
}

.blogNav-expandHolder{
    position: absolute;
    top: 32px;
    right: 0px;
    width: 20px;
    height: 20px;
    z-index: 5;
    @media (max-width: $first-breakpoint) {
        top: 6px;
    }
}
.mobileMenuOpen .blogNav-expandHolder{
    display: none !important;
}

@media (max-width: $mobile-breakpoint) {
    .blogNav-footer-iconHolder-hidden{
        display: none !important;
    }
    .blogNav-footer-iconHolder{
        width: 24% !important;
    }
}

