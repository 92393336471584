/* ==========================================================================
 Shared Elements
 ========================================================================== */
.linkArrow{
    display: inline-block;
    width: 24px;
    margin-left: 26px;
    svg{
        vertical-align: middle;
        width: 100%;
    }
}
.formHolder{
    .linkArrow{
        //margin-left: -26px;
    }
}

.footerCallout-block{
    min-height: 50vh;
    width: 100%;
    //display: table;
    .centreLock{
        //display: table-cell;
        width: 100%;
        //vertical-align: middle;
    }
    .footerCallout-block-content{
        padding-top: 40px;
        padding-bottom: 40px;
        .footerCallout-block-header{
            @include fp(font-size,12,18);
            color: $grey-text !important;
            margin-bottom: 15px;
        }
        .footerCallout-block-name{
            @include fp(font-size,32,42);
            line-height: 1em;
            margin-bottom: 0px;
            color: #ffffff !important;
        }
        .footerCallout-block-role{
            @include fp(font-size,32,42);
            line-height: 1em;
            color: $grey-text !important;
        }

        .footerCallout-block-copy{
            margin-top: calc(1.2em + 20px);
            padding-bottom: 20px;
            color: #ffffff !important;
            p{
              color: #ffffff !important;
            }
        }
        .footerCallout-block-link{
            text-decoration: none;
            font-size: 18px;

            color: $grey-text !important;

            &:hover {
              color: #fff !important;
            }
        }
    }
}

.getintouch{
    @media (min-width: 740px){
        display: flex;
        align-items: center;
    }
    .centreLock-margin{
        @media (min-width: 740px){
            display: flex;
            align-items: center;
        }
    }

    .portrait-image{
        max-width: 300px;
        margin: auto;
        width: 70%;
        @media (min-width: 740px){
            margin-left: 10px;
            margin-right: 10px;
            max-width: unset;
            width: 100%;
        }
    }
}

.footerCallout-block.bg--pink {
    .footerCallout-block-content{
        .footerCallout-block-header, .footerCallout-block-role{
            color: $faded-pink !important;
        }
        .footerCallout-block-link{
            color: $faded-pink !important;
            &:hover {
              color: #fff !important;
            }
        }
    }
}
.footerCallout-block.bg--red {
    .footerCallout-block-content{
        .footerCallout-block-header, .footerCallout-block-role{
            color: $pink !important;
        }
        .footerCallout-block-link{
            color: $pink !important;
            &:hover {
              color: #fff !important;
            }
        }
    }
}
.footerCallout-block.bg--white {
    .footerCallout-block-content{
        .footerCallout-block-header, .footerCallout-block-role{
            color: $black !important;
        }
        .footerCallout-block-link{
            color: $grey-text !important;
            &:hover {
              color: $black !important;
            }
        }
    }
}

@media (max-width: $mobile-breakpoint) {
    .footerCallout-block-content{
        .socialIconLinks{
            margin-top: 20px;
        }
    }
}

.socialLinks{
    list-style: none;
    margin: 0px;
    .socialLink-holder{
        margin: 0px;
        display: inline;
        margin-right: 20px;
        .socialLink{
            display: inline-block;
            color: #ffffff;
            text-decoration: none;
        }
    }
    .socialLink-holder:last-child{
        margin-right: 0px;
    }
}

.footerCallout-block-content{
    .socialLinks, .socialLinks a{
        font-size: 20px !important;
    }
}

.no-touch{
    .socialIconLinks:hover{
        a{
            @include opacity(0.6);
        }
    }
    .socialIconLinks{
        a{
            transition: opacity 0.3s;
        }
        a:hover{
            @include opacity(1);
        }
    }
}


.bookRecommendations{
    color: $black;
    p, a{
        color: $black;
    }
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    .bookRecommendations-header{
        color: $black;
        width:50%;
        margin-bottom: 40px;
    }
    .bookRecommendations-grid{
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .bookRecommendations-grid-col{
            display: flex;
            width: 30.666666666%;
            float: left;
            margin: 0px 0px 0px 4%;
            border-bottom: 1px dotted $black;
            padding: 40px 0px 40px 0px;
            .bookRecommendations-grid-col-content{
                flex: 1 0 auto;
                width: 100%;
                .book-title, .book-author, .book-link{
                    margin: 0px;
                }
                .book-title{
                    width: 100%;
                }
            }
        }
        .bookRecommendations-grid-col--first{
            margin-left: 0%;
        }
        .bookRecommendations-grid-col:nth-child(-n+3){
            border-top: 1px dotted $black;
        }
    }
}
.fp-responsive .bookRecommendations{
    .bookRecommendations-header{
        width:95%;
    }
    .bookRecommendations-grid{
        display: block;
        .bookRecommendations-grid-col{
            width: 100%;
            border-top: 0px;
            margin: 0px;
            display: inline-block;
            padding: 20px 0px 20px 0px;
        }
        .bookRecommendations-grid-col:first-child{
            border-top: 1px dotted $black;
        }
    }
}

.pageContentBlock-col{
    img{
        width: 100%;
    }
}
.pageContentBlock-inline-links{
    margin-top: 70px;
}
.pageContentBlock{
    padding-top: 80px;
    padding-bottom: 40px;
    //display: table-cell;
    vertical-align: middle;
    a{
        color: $pink;
        text-decoration: none;
    }
    a:hover{
        color: #ffffff;
    }
    h1{
        width: 66%;
    }
    h2,h3{
        color: $pink;
        margin-bottom: 30px;
    }
    .pageContentBlock-header{
        margin-bottom: 40px;
    }
    .pageContentBlock-subtitle{
        margin-bottom: 10px;
    }
    .pageContentBlock-col--first{
        padding-bottom: 40px;
        p{
            color: $pink;
        }
        p:first-child{
            color: #ffffff;
            margin-bottom: 20px;
        }
        .pageContentBlock-text:not(.largeCopy){
            p{
                color: #ffffff !important;
            }
        }
    }
    .pageContentBlock-col--last{
        padding-bottom: 40px;
    }
    .pageContentBlock-text p:last-child{
        margin-bottom: 0px;
    }
}
@media (max-width: $first-breakpoint) {
    .pageContentBlock{
        .pageContentBlock-link{
            white-space: nowrap;
        }
    }
}
@media (max-width: $mobile-breakpoint) {
    .pageContentBlock{
        padding-top: 60px;
        .pageContentBlock-header{
            margin-bottom: 20px !important;
        }
        .pageContentBlock .pageContentBlock-col{
            padding-bottom: 20px;
        }
        .pageContentBlock-col--first{
            padding-bottom: 20px;
        }
        .pageContentBlock-col--last{
            padding-bottom: 20px;
        }
    }
}
.page-education,
.page-ourTeamList,
.page-ourTeam,
.page-ourClients,
.bg--black{
    .pageContentBlock{
        a{
            color: #ffffff;
        }
        a:hover{
            color: $grey;
        }
        h2,h3{
            color: $grey-text;
        }
        .pageContentBlock-col--first{
            p{
                color: $grey-text;
            }
            p:first-child{
                color: #ffffff;
            }
        }
    }
}

.bg--pink{
    h1, h2, .pageContentBlock-subtitle{
        color: #ffffff !important;
    }
    .pageContentBlock a{
        color: $faded-pink !important;
    }
    .pageContentBlock .centreLock a:hover{
        color: #ffffff !important;
    }
}

.formHolder{
    padding-top: 80px;
    padding-bottom: 40px;
    //display: table-cell;
    vertical-align: middle;
    .formFields{
        padding-bottom: 20px;
    }
    h1{
        width: 66%;
    }
    a{
        color: #ffffff;
    }
    .linkArrow{
        svg{
            path,polygon{
                //stroke: #ffffff !important;
                fill: #ffffff !important;
            }
        }
    }
}
/*.no-touch .formHolder .submitHolder:hover{
    .linkArrow{
        svg{
            path{
                stroke: #ffffff !important;
            }
        }
    }
}*/

.socialLinks{
    list-style: none;
    margin: 0px;
    li{
        margin: 0px;
    }
}

.addressHolder{
    padding-bottom: 20px;
    h5{
        margin: 0px;
        color: $pink;
    }
    .googleMapLink{
        color: $pink;
    }
}

.mapHolder{
    width: 100%;
    height: 100%;
    //pointer-events: none;
}
.fp-responsive .mapHolder{
    height: 80vh;
}
.vp-short .mapHolder{
    height: 95vh;
}

.tags{
    .tags-content{
        .tagsList{
            .tagsList-item{
                display: inline-block;
            }
            .tagsList-link{
                a{
                    text-decoration: none;
                    color: #ffffff;
                }
            }
            .tagsList-separator{
                display: inline-block;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
}

.playIcon{
    display: block;
    width: 100px;
    svg{
        width: 100%;
    }
}
.js-podcastLink{
    .playIcon{
        pointer-events: none;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        @include opacity(0);
        transition: opacity 0.2s linear;
        will-change: opacity;
    }
}
.js-podcastLink:hover{
    .playIcon{
        @include opacity(1);
    }
}

.duotone{
    mix-blend-mode: multiply;
    @include opacity(0.6);
}




.socialMediaCountsHolder{
    width: 100%;
    height: 100%;
    height: 100vh;
    text-align: center;
    color: #ffffff;
    a{
        color: #ffffff;
        text-decoration: none;
    }
    .socialMediaCounts{
        width: 100%;
        height: 100%;
        .row, .columns{
            width: 100%;
            height: 100%;
        }
        .socialMediaCounts-block{
            width: 100%;
            height: 100%;
            display: table;
            .socialMediaCounts-copyHolder{
                display: table-cell;
                vertical-align: middle;
                transition: opacity 0.3s linear;
                .socialMediaCounts-copy{
                    display: block;
                    padding: 10px;
                    .socialIcon{
                        font-size: 35px;
                    }
                    .followerCount{
                        font-size: 18px;
                    }
                }
            }
        }
        .socialMediaCounts-block-blog{
            background-color: $red;
            font-size: 18px;
            .socialMediaCounts-copy p{
                max-width: 500px;
                margin: auto;
                @include fp(font-size,32,42);
                line-height: 1.1em;
                margin-bottom: 20px;
            }
        }
        .socialMediaCounts-block-twitter{
            background-color: $pink;
            height: 50%;
        }
        .socialMediaCounts-block-linkedin{
            background-color: $grey-text;
            height: 50%;
        }
        .socialMediaCounts-block-facebook{
            background-color: $black;
        }
        .socialMediaCounts-blockSplit{
            width: 50%;
            height: 100%;
            float: left;
        }
    }
    .socialMediaCounts:hover .socialMediaCounts-copyHolder{
        @include opacity(0.3);
    }
    .socialMediaCounts .socialMediaCounts-block:hover .socialMediaCounts-copyHolder{
        @include opacity(1);
    }
}

.chrome .socialMediaCountsHolder .socialMediaCounts .socialMediaCounts-blockSplit{
    overflow-x: hidden;
    margin-left: -1px;
    width: calc(50% + 1px);
    .socialMediaCounts-block{
        width: 101%;
    }
}

@media (min-width: 700px){
    .socialMediaCountsHolder .six.columns{
        width: 50%;
        margin: 0px;
    }
}

.page-ourBlogList, .page-ourBlog{
    .socialMediaCountsHolder{
        width: 100%;
        height: 100%;
        height: calc(100vh - 175px);
    }
}


.fp-responsive{
    .fp-slidesNav{
        top: inherit;
        bottom: 0px;
    }
    .fp-slides{
        margin-bottom: 40px;
    }
}


.footer{
    .linkArrow{
        svg polygon{
            fill: $grey-text;
        }
    }
}

.aboutServices-content{
    .pageContentBlock-content{
        width: 100%;
    }
    .pageContentBlock-subtitle{
        margin-bottom: 10px;
    }
}

.spinner {
    display: none;
    svg {
        width: 24px;
    }
}


.mobileCollapseNav{
    .mobileCollapseNav-link{
        width: 100%;
        cursor: pointer;
        .animatedTitle, h1, h2{
            margin-bottom: 10px !important;
        }
    }
    .mobileCollapseNav-content{
        margin-bottom: 20px;
        width: 100%;
    }
}
@media (max-width: $menu-breakpoint) {
    .mobileCollapseNav .mobileCollapseNav-content{
        ul{
            width: 100% !important;
        }
    }
}


.collapseListIcon{
    position: absolute;
    right: 0px;
    top: 0.4em;
    display: none;
    width: 18px;
    svg{
        width: 100%;
    }
}

@media (max-width: $menu-breakpoint){
    .mobileCollapseNav{
        .mobileCollapseNav-link{
        }
        .mobileCollapseNav-content{
            display: none;
        }
    }
    .mobileCollapseNav.opened{
        .mobileCollapseNav-link{
        }
        .mobileCollapseNav-content{
        }
        .collapseListIcon{
            .top,.bottom{
                display: none !important;
            }
        }
    }
    .collapseListIcon{
        display: block;
    }
}


.mobileCollapseNav-content .allSelected.hover{
    a,.nav-separator{
        @include opacity(0.6);
    }
    a:hover{
        @include opacity(1,true);
    }
}


.bg--white{
    .flickity-page-dots{
        .dot{
            background: $black;
        }
    }
}

.vp-short{
    .homeSection-cols .homeSection-content .homeSection-col .homeSection-col-block{
        width: 50%;
        display: inline-block;
        float: left;
        height: 50vh;
    }
    .pageContent .section .homeSection .homeSection-content{
        padding-bottom: 40px !important;
    }
    .socialMediaCounts{
        height: 100vh;
    }
    .education-section{
        padding-bottom: 60px !important;
    }
    .pageContent-education{
        .scrollSlides .section:first-child{
            padding-bottom: 60px !important;
        }
        .fp-scrollable{
            overflow: visible;
            .fp-scroller{
                overflow: visible;
            }
        }
    }

    .page-ourBlogList .socialMediaCountsHolder,
    .page-ourBlog .socialMediaCountsHolder{
        height: auto !important;
    }
}

.footerCalloutBlock-education{
    a,p a{
        color: #ffffff;
    }
    .footerCallout-block-content .footerCallout-block-link{
        color: #ffffff !important;
        @include opacity(0.6);
    }
    .footerCallout-block-content .footerCallout-block-link:hover{
        @include opacity(1);
    }
}

/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
    pointer-events: none;
    z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
    pointer-events: auto;
    z-index: 1;
    transition: opacity 0.4s linear;
}
