@import "~rangeslider.js/dist/rangeslider.css";


/* ==============
    Intro Slider positional setup
   ============== */

.introSlider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    .centreLock,
    .centreLock-margin {
        height: 100%;
        height: 100vh;
    }
    .introSlider-content {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 80px 0px 20px 0px;
    }
    .fp-scrollable .fp-scroller{
        height: 100%;
        width: 100%;
        overflow: visible;
    }
}
@media (max-width: $mobile-breakpoint) {
    .introSlider {
        .introSlider-content {
            padding: 60px 0px 20px 0px;
        }
    }
}
@media (min-width: $first-breakpoint) {
    .introSlider {
        .introSlider-content {
            padding: 80px 50px 20px 0px;
        }
    }
}


/* ==========================
    Intro Slider - Content Sets
   ========================== */

.introSlider-contentSets {
    .introSlider-imageHolder{
        display: none;
    }
    .images-set{
        .pos1,.pos2,.pos3,.pos4,.pos5{
            display: block;
        }
        .pos1 {
            width: 16%;
            left: 0%;
            bottom: 90px;
            //top: calc(100% - 111px - 16%);
        }
        .pos2 {
            width: 34.2%;
            left: 18.5%;
            bottom: 90px;
            //top: calc(100% - 111px - 16% - 29.1%);
        }
        .pos3 {
            width: 16%;
            left: 55.4%;
            //top: calc(100% - 111px - 16% - 29.1%);
            bottom: 90px;
            transform: translateY(-113%);
        }
        .pos4 {
            width: 16%;
            left: 74%;
            //top: calc(100% - 111px - 16% - 45.2%);
            bottom: 90px;
            transform: translateY(-175%);
        }
        .pos5 {
            width: 25.5%;
            left: 74%;
            //top: calc(100% - 111px - 16% - 16.1%);
            bottom: 90px;
        }
    }

    .icons-set{
        .pos1,.pos2,.pos3,.pos4,.pos5{
            display: block;
        }
        .pos1{
            width: 16%;
            left: 18%;
            top: calc(100% - 111px - 16%);
        }
        .pos2 {
            width: 16%;
            left: 36.7%;
            top: calc(100% - 111px - 16% - 8%);
        }
        .pos3 {
            width: 16%;
            left: 66%;
            top: 10px;
        }
        .pos4 {
            width: 16%;
            left: 85%;
            top: calc(10px + 8%);
        }
        .pos5 {
            width: 16%;
            left: 66%;
            top: calc(100% - 111px - 16%);
        }
    }

    .imagesIcons-set{
        .pos1,.pos2,.pos3,.pos4,.pos5,.pos6{
            display: block;
        }
        .pos1{
            width: 16%;
            left: 14%;
            bottom: 90px;
            transform: translateY(-80%);
            z-index: 2;
        }
        .pos2 {
            width: 25.5%;
            left: 54.7%;
            top: 20px;
            z-index: 2;
        }
        .pos3 {
            width: 32.3%;
            right: 0%;
            top: 20px;
            transform: translateY(33%);
        }
        .pos4 {
            width: 16%;
            left: 0%;
            bottom: 90px;
        }
        .pos5 {
            width: 16%;
            left: 36.7%;
            bottom: 90px;
        }
        .pos6 {
            width: 16%;
            right: 0%;
            top: 10px;
        }
    }
}

.vp-short{
    .introSlider-contentSets {
        .images-set{
            .pos1, .pos2, .pos3, .pos4, .pos5{
                transform: none;
            }
            .pos2{
                width: 27%;
                left: 80%;
                top: 50%;
                bottom: inherit;
                transform: translateY(-50%);
            }
            .pos1, .pos3, .pos4{
                display: none;
            }
            .pos5 {
                width: 27%;
                left: 51%;
                top: 0%;
                bottom: inherit;
                transform: translateY(-78%);
            }
        }

        .icons-set{
            .pos1{
                width: 27%;
                left: 80%;
                top: 50%;
                transform: translateY(-50%);
            }
            .pos2, .pos3, .pos4{
                display: none;
            }
            .pos5 {
                width: 27%;
                left: 51%;
                top: 0%;
                bottom: inherit;
                transform: translateY(-78%);
            }
        }

        .imagesIcons-set{
            .pos2{
                width: 27%;
                left: 80%;
                top: 50%;
                transform: translateY(-50%);
            }
            .pos1, .pos5, .pos6{
                display: none;
            }
            .pos4{
                width: 19%;
                left: 50%;
                top: 50%;
                transform: translateY(-30%);
            }
            .pos3 {
                width: 38.4%;
                left: 36%;
                top: 0%;
                bottom: inherit;
                transform: translateY(-78%);
            }
        }
    }
}

@media (max-width: $mobile-breakpoint) {
    .introSlider-contentSets {
        .images-set{
            .pos1, .pos2, .pos3, .pos4, .pos5{
                transform: none;
            }
            .pos2{
                width: 75%;
                left: 50%;
                top: 0px;
                bottom: inherit !important;
            }
            .pos1, .pos3, .pos4{
                display: none;
            }
            .pos5 {
                width: 55%;
                left: -30%;
                top: 40%;
                bottom: inherit !important;
            }
        }

        .icons-set{
            .pos2{
                width: calc(50% - 10px);
                left: calc(50% + 10px);
                top: 38%;
                bottom: inherit !important;
                transform: translateY(50%);
            }
            .pos1, .pos3{
                display: none;
            }
            .pos4{
                width: calc(50% - 10px);
                left: calc(100% - 40px);
                top: 40px;
                bottom: inherit !important;
            }
            .pos5 {
                width: calc(40% - 10px);
                left: 0%;
                top: 50%;
                bottom: inherit !important;
            }
        }

        .imagesIcons-set{
            .pos1, .pos2, .pos3, .pos4, .pos5{
                transform: none;
            }
            .pos2{
                width: 75%;
                left: -30%;
                top: 40px;
                bottom: inherit !important;
            }
            .pos1, .pos5, .pos6{
                display: none;
            }
            .pos4{
                width: 38%;
                left: 16%;
                top: 60%;
                transform: translateY(-50%);
                bottom: inherit !important;
                z-index: 3;
            }
            .pos3 {
                width: 55%;
                left: 70%;
                top: 60%;
                bottom: inherit !important;
                transform: translateY(-50%);
            }
        }
    }
}



.fp-responsive .introSlider .introSlider-content {
    height: 100%;
    height: 100vh;
    .introSlider-contentSets{
        height: 100%;
        .introSlider-contentSets-set{
            height: 100%;
        }
    }
}

.introSlider-contentSets {
    display: none;
    position: relative;
    height: 100%;
    height: calc(100% - 20px);
    margin-bottom: 20px;
    pointer-events: none;
    //overflow: hidden;
}

.introSlider-contentSets-set {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.introSlider-contentSets-set.is-fadeIn{
    pointer-events: auto;
}

.introSlider-contentSets-set>div {
    @include opacity(0);
    transform: translate3d(0, 0, 0) scale(0.05);
    position: absolute;
    content: " ";
    display: table;
    clear: both;
}

.introSlider-contentSets-set>div img {
    width: 100%;
    height: auto;
    pointer-events: none;
}

.introSlider-contentSets-set>.introSlider-contentSets-set-header {
    position: relative;
    @include opacity(0);
    margin-top: 0px;
    @extend .accelerated;
    z-index: 4;
}


/* ---- FADE IN ---- */

.introSlider-contentSets-set.is-fadeIn>div {
    transition: transform 0.3s ease 0.6s, opacity 0.2s linear 0.6s;
    @include opacity(1);
    transform: scale(1);
}

.introSlider-contentSets-set.is-fadeIn>.introSlider-contentSets-set-header {
    @include opacity(1);
    margin-top: 0px;
    transition: margin-top 0.8s ease, opacity 0.8s linear;
}

@mixin animationIn($anim_delay, $anim_duration) {
    $extra_delay: 0.3;
    .introSlider-contentSets-set>div[data-animation-delay='#{$anim_delay}'],
    .floaty[data-animation-delay='#{$anim_delay}'] {
        transition: transform $anim_duration*1s cubic-bezier(0.215, 0.61, 0.355, 1) ($anim_delay+$extra_delay)*1s, opacity $anim_duration*1s linear ($anim_delay + $extra_delay + 0.1)*1s;
    }
    $floatX_anim_duration: 4.2;
    $floatY_anim_duration: 3.0;
    .introSlider-contentSets-set>div[data-animation-delay='#{$anim_delay}'] img,
    .floaty[data-animation-delay='#{$anim_delay}'] img {
        animation: floatX ($floatX_anim_duration+$anim_delay)*1.5s ease-in-out infinite;
        animation-delay: (-5*$anim_delay+5)*0.5s;
    }
    .introSlider-contentSets-set>div[data-animation-delay='#{$anim_delay}'] img,
    .floaty[data-animation-delay='#{$anim_delay}'] img {
        animation: floatY ($floatY_anim_duration+$anim_delay)*1.5s ease-in-out infinite;
        animation-delay: (-5*$anim_delay+5)*0.5s;
    }
}

$duration: 0.4;
@include animationIn(0.1, $duration);
@include animationIn(0.2, $duration);
@include animationIn(0.3, $duration);
@include animationIn(0.4, $duration);
@include animationIn(0.5, $duration);
@include animationIn(0.6, $duration);
@include animationIn(0.7, $duration);
@include animationIn(0.8, $duration);
@include animationIn(0.9, $duration);
@include animationIn(1.0, $duration);
@include animationIn(1.1, $duration);
@include animationIn(1.2, $duration);

/* ---- FADE OUT ---- */

@mixin animationOut($anim_in_name, $anim_delay, $anim_duration) {
    .introSlider-contentSets-set.is-fadeOut>div[data-animation-delay='#{$anim_in_name}'] {
        transition: transform $anim_duration*1s linear ($anim_delay+0.15)*1s, opacity $anim_duration*1s linear ($anim_delay + 0.05)*1s;
    }
}

.introSlider-contentSets-set.is-fadeOut>.introSlider-contentSets-set-header {
    transition: opacity 0.1s linear;
}

$duration: 0.25;
@include animationOut(0.1, 0.2, $duration);
@include animationOut(0.2, 0.25, $duration);
@include animationOut(0.3, 0.3, $duration);
@include animationOut(0.4, 0.35, $duration);
@include animationOut(0.5, 0.4, $duration);
@include animationOut(0.6, 0.45, $duration);
@include animationOut(0.7, 0.5, $duration);
@include animationOut(0.8, 0.55, $duration);
@include animationOut(0.9, 0.6, $duration);
@include animationOut(1.0, 0.65, $duration);
@include animationOut(1.1, 0.7, $duration);
@include animationOut(1.2, 0.75, $duration);



/* ---- FLOATING  ---- */

@keyframes floatX {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes floatY {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}


/* ======================
    Intro Slider - Slider
   ====================== */

.introSlider-content-slider {
    position: relative;
    bottom: 80px;
    @include opacity(0);
    z-index: 5;
}

.introSlider-content-slider.is-inited {
    display: block;
    @include opacity(1);
    transition: opacity 0.2s linear 0.55s;
}

.introSlider-contentSets.is-inited {
    display: block;
}

.introSlider-content-slider-label {
    width: 200px;
    position: absolute;
    margin-top: 15px;
}

.introSlider-content-slider-label--right {
    text-align: right;
    right: 0;
}

body:not(.fp-intro-slider) .introSlider-contentSets {
    .introSlider-imageHolder{
        //technically better, but causes big delay when first scrolling down page
        //animation-play-state: paused !important;
        animation: none !important;
        transition: none !important;
    }
}
.introSlider-contentSets-set:not(.is-fadeIn){
    .introSlider-imageHolder{
        animation-play-state: paused !important;
    }
}

.introSlider-contentSets-set-cta{
    margin-top: 40px;
    .featuredBlock-footerLink-col{
        margin-top: 0px;
        padding-bottom: 20px;
    }
}
.vp-short .introSlider-contentSets-set-cta{
    .featuredBlock-footerLink-col{
        width: auto !important;
        padding-right: 40px;
    }
}

.introSlider-contentSets-set-form{
    margin-top:40px;
    .submitHolder{
        .linkArrow{
            .link-arrow-svg .arrow{
                //stroke: #ffffff !important;
                fill: #ffffff !important;
            }
        }
    }
}

@media (max-width: $first-breakpoint) {
    .introSlider-contentSets-set-form{
        margin-top:15px;
    }
}

.vp-short .introSlider-contentSets-set-form{
    margin-top: 20px;
    .formField-name, .formField-email{
        width: 40%;
        width: calc(50% - 10px);
        display: inline-block;
        float: left;
    }
    .formField-name{
        padding-right: 10px;
    }
    .formField-email{
        padding-left: 10px;
    }
}

/* ====================================
   Intro Slider - Rangeslider.js overrides
   ==================================== */

.rangeslider {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    cursor: s-resize;
}

.rangeslider--horizontal.rangeslider::after {
    display: block;
    content: " ";
    width: 100%;
    height: 3px;
    background: $faded-red;
    position: relative;
    top:100%;
    top: calc(100% + 1px);
}

.rangeslider__fill {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
}

.rangeslider__handle {
    position: absolute;
    top: 0px;
    width: 30px;
    height: 17px;
    display: inline-block;
    z-index: 3;
    cursor: ew-resize;
    border: none !important;
    background: transparent !important;
    background-image: none !important;
    box-shadow: none;
}

.rangeslider__handle:after {
    top:24px;
    background-image: url("../svg-src/arch-small-white.svg");
    border: none !important;
    background-color: transparent !important;
    border-radius: 0px;
    width: 100%;
    height: 100%;
}

.rangeslider__handle:after svg {
    fill: white;
}


/* Generated from introSlider.js - currently unused */

.rangeslider__markers>span {
    display: none;
    position: absolute;
    width: 2px;
    height: 12px;
    background: rgb(255, 255, 255);
    box-sizing: border-box;
    margin-left: 2px;
    margin-top: 5px;
}


.sliderBounce .rangeslider__handle{
    animation: bridgeBounce 12s infinite;
    animation-delay: 8s;
}

/*@keyframes bridgeBounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
    40% {
    transform: translateY(-10px);
  }
    60% {
    transform: translateY(-5px);
  }
}*/
$offsetCnt: 0.06;
@keyframes bridgeBounce {
  0%, 1.2%, 3%, 4.8%, 6% {
    transform: translateY(0);
  }
    2.4% {
    transform: translateY(-10px);
  }
    3.6% {
    transform: translateY(-5px);
  }
}


.introSlider-contentSets-set{
    .delayedDisplayCTA{
        @include opacity(0,true);
        transform: translateY(10px) !important;
        a:hover{
            color: #fff !important;
        }
    }
}
.introSlider-contentSets-set.is-fadeIn{
    .delayedDisplayCTA{
        @include opacity(1,true);
        transform: translateY(0px) !important;
    }
}
