.cc-w {
  color: inherit;
  font-size: inherit;
  @extend .ff-mark;
  margin-top: 3rem;
}
.cc-w .cc-i-header {
  background: transparent;
}
.comments {
  padding: 6rem 0 0;
}
.cc-w .cc-i {
  display: flex;
  border-top: 0;
  margin-bottom: 3rem;

  form {
    label {
      margin-bottom: .5rem;
    }
    input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
      max-width: unset;
      background: $grey;
      &::placeholder {
        color: #cdcecf;
        @extend .ff-mark-bold;
      }
      //font-size: 1.5em;
      padding: 6px 10px;
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        background: $grey !important;
    }
  }
}

.bg--pink{
    .cc-w .cc-i form{
        input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
            background: $faded-pink !important;
            color: #ffffff !important;
            &::placeholder {
                color: #ffffff !important;
            }
        }
    }
}

.cc-w .cc-i-body {
  padding: 0;
  line-height: 1.4em;
}
.cc-w .cc-i-header, .cc-w .cc-i-footer {
  font-size: 1em;
}
.cc-w .cc-ll-i::before {
  content: unset;
}
.cc-ll-actions {
  justify-content: flex-end;
  flex: 2 !important;

  .cc-ll-i {
    margin-right: 2em;
    white-space: nowrap;

    a {
      text-decoration: underline;
    }

    /*&:last-child {
      margin-right: 0;
    }*/
  }

  [data-action="closeReplies"] {
    display: none;
  }
}
.cc-w .cc-i-figure {
  flex: 1;
  margin-right: 12rem;

  @media (max-width: $desktop-breakpoint - 1) {
    margin-right: 6rem;
  }
}
.cc-w .cc-i-wrap {
  flex: 5;
}
.cc-w .cc-i-image {
  width: 100%;
  height: 0;
  padding-top: 50%;
  padding-bottom: 50%;
  position: relative;
  border-radius: 0;
}
.cc-w img {
  position: absolute;
  top: 0;
  left: 0;
}
.cc-w .cc-i .cc-i .cc-i-image {
  width: 100%;
  height: 0;
}
.cc-i .cc-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  vertical-align: middle;
  svg{
    width: 100%;
    rect, circle, path, polygon{
      fill: $black;
    }
  }
}
.cc-w .cc-i-header-links {
  display: block;
  margin-left: 0;
}
.cc-w .cc-i-header-links.has-flag, .cc-w .cc-i-content:hover .cc-i-header-links {
  margin-right: 0;
}
.cc-w .cc-i-flag {
  margin-right: 1rem;
}
.cc-i-footer {
  display: flex;
  padding-top: 3rem;

  .cc-ll {
    flex: 1;
  }
}
.cc-i-wrap [data-role="replies"] {
  display: none;

    //[data-role="replies"] {
        //overflow-x: auto;
        article.cc-i {
            //min-width: 950px;
        }
    //}

    [data-role="reply"] .cc-i.cc-f {
        padding: 3rem 0;
    }

    .cc-i {
    padding: 3rem; margin: 0;
    //background: #fff;

    &:first-child {
      margin-top: 3rem;
    }

    .cc-i-wrap [data-role="replies"] .cc-i {
      //background: $light-gray;

      .cc-i-wrap [data-role="replies"] .cc-i {
        //background: #fff;
      }
    }
  }
  .cc-ll {
      &--name {
        flex: 2;
      }
  }
}
.cc-w .cc-i-image-icon {
  background: $grey;
  width: 100%;
  margin-top: -50%;
  padding-bottom: 100%;
  display: block;
}
.cc-w-i > .featuredBlock-footerLink-col {
  margin-top: 5rem;

  svg {
    polygon {
      fill: $black
    }
  }
}

.cc-i {
  .submitHolder {
    input[type="submit"] {
      color: $black;
    }
    svg {
      polygon {
        fill: $black
      }
    }
  }
}

.cc-w > .featuredBlock-header {
  margin-top: 4rem;
}

.cc-i.cc-f .replyOnly {
  display: none;
}

[data-role="reply"] {
  //overflow-x: auto;

  .cc-i.cc-f {
    display: block;

    //background: #fff;
    padding: 3rem;
    margin: 2rem 0 0;

    form {
      margin-bottom: 0;
    }

    .replyOnly {
      display: block;
    }
  }
}

.cc-w-i {
    [data-action="hideAllComments"] {
        display: none;
    }
    [data-action="viewAllComments"] {
        display: block;
    }

    &.viewAllComments {
        [data-action="hideAllComments"] {
            display: block;
        }
        [data-action="viewAllComments"] {
            display: none;
        }
    }
}

.cc-w-i hr {
  margin-top: 4rem;
  background: transparent;
  border: 0;
  border-top: 1px dotted $black;

  @media (max-width: $mobile-breakpoint - 1) {
    //display: block;
  // margin-top: 5rem;
  }
}

#comments{
    &.bg--pink, &.bg--red{
        .cc-w-i hr{
            border-color:#ffffff !important;
        }
        .cc-i-wrap .sub-button,
        .featuredBlock-footerLink-col,
        .featuredBlock-footerLink-link,
        .commentTime,.cc-i, .cc-ll-i a{
            color: #ffffff !important;
        }
        .linkArrow,.cc-icon {
            color: #ffffff;
            polygon,path {
                fill: #ffffff;
            }
        }
    }
}

[data-role="comments"] {
  > .cc-i {
    display: none;

    &:first-child {
      display: flex;
      @media (max-width: $mobile-breakpoint - 1) {
        display: block;
      }
    }
  }

  &.viewAllComments {
    > .cc-i {
      display: flex;
      @media (max-width: $mobile-breakpoint - 1) {
        display: block;
      }
    }
  }
}

@media (min-width: $mobile-breakpoint) {
  .commentHeader {
    display: none;
  }
}

@media (max-width: $mobile-breakpoint - 1) {
  [data-role="replies"] .cc-i {
    display: block;
  }
  .cc-w .cc-i-figure {
    margin-right: 0;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;

    .cc-i-image {
      width: 64px;
      height: 64px;
      padding: 0;

      .cc-i-image-icon {
        margin-top: 0;
      }
    }

    .commentHeader {
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .cc-ll {
        margin-top: 0.7rem;
      }
    }
  }
  .cc-w .cc-i-wrap {
    width: 100%;
  }
  .cc-w .cc-i .cc-i .cc-i-image {
    width: 64px;
    height: 64px;
  }
}
