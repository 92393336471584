/* ==========================================================================
 Forms
 ========================================================================== */

label {
    @extend .ff-mark;
    font-size: 12px;
    font-weight: normal;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
    border: 0px !important;
    border-radius: 0px !important;
    background-color: #f9f9f9;//$grey;
    color: $grey-text !important;
    font-size: 18px;
    width: 100%;
    max-width: 400px;
}
textarea{
    min-height: 150px;
}
::placeholder {
    color: $grey-text;
    opacity: 1;
}
::-webkit-resizer {
  display: none;
}
input[type="submit"]{
    display: block;
    border: 0px !important;
    border-radius: 0px !important;
    background-color: none;
    color: $grey-text;
    font-weight: normal;
    text-transform: none;
    @extend .subheader-size;
    @extend .ff-mark-bold;
    line-height: 1.1em;
    letter-spacing: 0px;
    padding-left: 0px;
    padding-right: 50px;
}

.submitHolder{
    position: relative;
    display: inline-block;
    .sub-button{
        float: left;
        display: inline-block;
        padding-right: 27px;
    }
    .linkArrow{
        float: left;
        //position: absolute;
        display: inline-block;
        pointer-events: none;
        //top: 12px;
        //left: 85px;
        margin-left: 0px;
        margin-top: 9px;
        svg{
            path{
                stroke: $grey-text !important;
            }
        }
    }
}
/*.no-touch .submitHolder:hover{
    .linkArrow{
        svg{
            path{
                stroke: $black !important;
            }
        }
    }
}*/

.footer-container form{
    margin-top: 10px;
}

.bg--red{
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="text"],
    input[type="tel"],
    input[type="url"],
    input[type="password"],
    textarea {
        background-color: $faded-red;
        color: $pink !important;
    }
    input[type="submit"]{
        color: #ffffff;
    }
    ::placeholder {
        color: $pink;
        opacity: 1;
    }

    .submitHolder {
        .sub-button {
            color: $pink !important;
            &:hover {
                color: #fff !important;
            }
        }
    }
}

.page-education{
    .educationForm-holder{
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="text"],
        input[type="tel"],
        input[type="url"],
        input[type="password"],
        textarea {
            background-color: #64666a;
            color: #a4a5a7 !important;

            &:-webkit-autofill {
                -webkit-box-shadow: inset 0 0 0px 9999px #64666a;
            }
        }
        ::placeholder {
            color: $grey-text;
            opacity: 1;
        }

        .submitHolder{
            .sub-button{
                color: $grey-text !important;
                .linkArrow{
                    .link-arrow-svg{
                        .arrow{
                            fill: #ffffff !important;
                            //stroke: #ffffff !important;
                        }
                    }
                }
            }
            .sub-button:hover{
                color: #ffffff !important;
            }
        }
    }
}

.form-submitting, .form-confirm, .form-error{
    display: none;
    width: 100%;
    clear: both;
}

.form-submitting, .form-confirm{
    @extend .subheader-size;
    @extend .ff-mark-bold;
    line-height: 1.1em;
}

.footerCols{
    .sub-button:hover{
        color: $black;
    }
}
