/* ==========================================================================
 Our Clients
 ========================================================================== */
.clientLogos-section{
    .centreLock{
        //display: table-cell;
        vertical-align: middle;
        margin-top: 40px;
    }
}
.clientLogos{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    .clientLogos-link{
        @include opacity(0.6);
        will-change: opacity;
        transition: opacity 0.3s linear;
    }
    .clientLogos-link:hover{
        @include opacity(1);
    }
    .clientLogo{
        width: 50%;
        padding: 10px;
        box-sizing: border-box;
    }
}

@media (min-width: $first-breakpoint) {
    .clientLogos{
        .clientLogo{
            width: 25%;
        }
    }
}

@media (min-width: $second-breakpoint) {
    .clientLogos{
        .clientLogo{
            width: 16.66667%;
        }
    }
}

@media (max-width: $mobile-breakpoint) {
    .clientLogos{
        padding-top: 0px !important;
    }
}

.clientLogosMobile{
    display: none;
    padding-top: 80px;
    padding-bottom: 20px;
    .logoSlide{
        width:100%;
        .logoCol{
            display: inline-block;
            float: left;
            width:50%;
            text-align: center;
            img{
                text-align: center;
                width: 85%;
                margin: auto;
            }
        }
    }
}

@media (max-width: $first-breakpoint) {
    .clientLogos{
        display: none;
    }
    .clientLogosMobile{
        display: block;
    }
}