/* ==========================================================================
 Privacy
 ========================================================================== */

 .privacyHolder{
 	position: relative;
 	@extend .ff-mark;
 	padding-bottom: 80px;
 	.privacy-nav{
 		position: relative;
 		position: -webkit-sticky;
 		position: sticky;
 		top: 130px;
 		left: 0px;
 		list-style: none;
 		margin: 0px;
 		margin-top: 20px;
 		height: auto;
 		li{
 			margin: 0px;
 			display: block;
 			width: 100%;
 			a{
 				color: #ffffff !important;
 				text-decoration: none;
 			}
 		}
 	}

 	.privacy-content{
 		h2{
 			@include fp(font-size,38,48);
 		}
 		.privacySection{
 			margin-top: 0px;
 			padding-right: 40px;
            padding-top: 120px
 		}
        .privacySection:first-child{
            padding-top: 40px
        }
 		#privacy{

 		}
 		#terms{

 		}
 		#quality{

 		}
 	}
 }

@media (max-width: $mobile-breakpoint) {
    .privacy-nav{
        display: none;
    }
}

.privacyHolder .privacy-nav{
    ul:hover li{
        a.privacyNavLink{
            color: $pink !important;
        }
        a:hover{
            color: #fff !important;
        }
    }
}
