/* ==========================================================================
 Typography
 ========================================================================== */

/* Fonts */
@import url("//hello.myfonts.net/count/372de5");
@font-face {
    font-family: 'FFMark';
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    src: url('https://wearebro.dk/fonts/markpro/372DE5_3_0.eot');
    src: url('https://wearebro.dk/fonts/markpro/372DE5_3_0.eot?#iefix') format('embedded-opentype'),
    url('https://wearebro.dk/fonts/markpro/372DE5_3_0.woff2') format('woff2'),
    url('https://wearebro.dk/fonts/markpro/372DE5_3_0.woff') format('woff'),
    url('https://wearebro.dk/fonts/markpro/372DE5_3_0.ttf') format('truetype'),
    url('https://wearebro.dk/fonts/markpro/372DE5_3_0.svg#wf') format('svg');
}

/*@font-face {
    font-family: 'FFMark';
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
    src: url('https://wearebro.dk/fonts/markpro/372DE5_4_0.eot');
    src: url('https://wearebro.dk/fonts/markpro/372DE5_4_0.eot?#iefix') format('embedded-opentype'),
    url('https://wearebro.dk/fonts/markpro/372DE5_4_0.woff2') format('woff2'),
    url('https://wearebro.dk/fonts/markpro/372DE5_4_0.woff') format('woff'),
    url('https://wearebro.dk/fonts/markpro/372DE5_4_0.ttf') format('truetype'),
    url('https://wearebro.dk/fonts/markpro/372DE5_4_0.svg#wf') format('svg');
}*/

@font-face {
    font-family: 'FFMarkBold';
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
    src: url('https://wearebro.dk/fonts/markpro/372DE5_7_0.eot');
    src: url('https://wearebro.dk/fonts/markpro/372DE5_7_0.eot?#iefix') format('embedded-opentype'),
    url('https://wearebro.dk/fonts/markpro/372DE5_7_0.woff2') format('woff2'),
    url('https://wearebro.dk/fonts/markpro/372DE5_7_0.woff') format('woff'),
    url('https://wearebro.dk/fonts/markpro/372DE5_7_0.ttf') format('truetype'),
    url('https://wearebro.dk/fonts/markpro/372DE5_7_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'FontAwesome5Brands';
  font-style: normal;
  font-weight: normal;
  src: url("https://wearebro.dk/fonts/fontawesome/fa-brands-400.eot");
  src: url("https://wearebro.dk/fonts/fontawesome/fa-brands-400.eot?#iefix") format("embedded-opentype"),
  url("https://wearebro.dk/fonts/fontawesome/fa-brands-400.woff2") format("woff2"),
  url("https://wearebro.dk/fonts/fontawesome/fa-brands-400.woff") format("woff"),
  url("https://wearebro.dk/fonts/fontawesome/fa-brands-400.ttf") format("truetype"),
  url("https://wearebro.dk/fonts/fontawesome/fa-brands-400.svg#fontawesome") format("svg");
}

.ff-mark {
    font-family: 'FFMark', "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: normal;
}
.ff-mark-bold {
    font-family: 'FFMarkBold', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: normal;
}
.fab {
  font-family: 'FontAwesome5Brands';
}


/* Typographic Rules */
body,
.body-reg {
    @extend .ff-mark-bold;
    font-weight: normal;
}


.header-1 {
    @extend .ff-mark-bold;
    @include fp(font-size,15,30);
    letter-spacing: 0em;
}

.header-2 {
    @extend .ff-mark-bold;
    @extend .subheader-size;
    letter-spacing: 0em;
}

.header-3 {
    @extend .bodycopy-size;
    letter-spacing: 0em;
}

footer {
    text-align: left;
}

p {
    margin-top: 0;
}

.uppercase {
    text-transform: uppercase;
}


/* NOTE
html is set to 62.5% so that all the REM measurements throughout Skeleton
are based on 10px sizing. So basically 1.5rem = 15px :) */

html {
    font-size: 62.5%;
}

body {
    font-size: 1.5em;
    /* currently ems cause chrome bug misinterpreting rems on body element */
    line-height: 1.4;
    //letter-spacing: 0.05em;
    @extend .font-smoothing;
    @extend .bodycopy-size;
    color: $text-col;
    word-break: keep-all;
}

/* Text select */
::selection {
  background: $highlight;
  color: $highlight-text;
}
::-moz-selection {
  background: $highlight;
  color: $highlight-text;
}

h1,h2,h3,h4,h5,h6 {
    color: $text-col;
    margin-top: 0;
    margin-bottom: 2.3rem;
    font-weight: normal;
    @extend .font-smoothing;
    letter-spacing: -0.05em;
}

h1 {
    @extend .header-1;
}

h2 {
    @extend .header-2;
}

h3,h4,h5,h6 {
    @extend .header-3;
}

figure {
    margin: 0px;
}

b,strong {
    font-weight: bold;
}

em {
    font-style: italic !important;
}

p{
    margin-bottom: 1.2em;
    color: $text-col;
}

.huge-header-size{
    @include fp(font-size,40,90);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.large-header-size{
    @include fp(font-size,26,60);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.largeLink-size{
    @include fp(font-size,24,48);
    line-height: 1.1em;
}
.header-size{
    @include fp(font-size,21,42);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.small-header-size{
    @include fp(font-size,16,32);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.small-header-size2{
    @include fp(font-size,15,30);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.large-subheader-size{
    @include fp(font-size,18,24);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.mediumsubheader-size{
    @include fp(font-size,16,20);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.subheader-size{
    @include fp(font-size,15,18);
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.largecopy-size{
    @include fp(font-size,14,16);
}
.bodycopy-size{
    @include fp(font-size,12,14);
}
.smallcopy-size{
    @include fp(font-size,10,12);
}

// Type sizes to apply above breakpoint, old blog sizes
// New type sizes to apply below this point, for tablet and mobile
// Set to same breakpoint as grid breakpoint
$blogTypeBreakpoint: 740;
$blogTypeBreakpointPX: 740px;
.blogheader-size{
    @include fp(font-size, 36, 72, 375, $blogTypeBreakpoint, true, true, false);
    @media (min-width: $blogTypeBreakpointPX){
        @include fp(font-size, 26, 60, $blogTypeBreakpoint, $max-width, true, false, true);
    }
    line-height: 1.1em;
    letter-spacing:-.05em;
}
.blogtitle-size{
    @include fp(font-size, 24, 48, 375, $blogTypeBreakpoint, true, true, false);
    @media (min-width: $blogTypeBreakpointPX){
        @include fp(font-size, 21, 42, $blogTypeBreakpoint, $max-width, true, false, true);
    }
    line-height: 1.1em;
    letter-spacing:-.05em;
}
.blogcopy-size{
    @include fp(font-size, 16, 28, 375, $blogTypeBreakpoint, true, true, false);
    @media (min-width: $blogTypeBreakpointPX){
        @include fp(font-size, 16, 18, $blogTypeBreakpoint, $max-width, true, false, true);
    }
    line-height: 1.11em;
    letter-spacing: 0.01em;
}
.blogquote-size{
    @include fp(font-size, 20, 36, 375, $blogTypeBreakpoint, true, true, false);
    @media (min-width: $blogTypeBreakpointPX){
        @include fp(font-size, 22, 24, $blogTypeBreakpoint, $max-width, true, false, true);
    }
    line-height: 1.1em;
}
.blogdetails-size{
    @include fp(font-size, 12, 24, 375, $blogTypeBreakpoint, true, true, false);
    @media (min-width: $blogTypeBreakpointPX){
        //@include fp(font-size, 14, 16, $blogTypeBreakpoint, $max-width, true, false, true);
        font-size: 14px !important;
    }
    line-height: 1.1em;
}
.blogcta-size, .blogauthor-size{
    @extend .blogcopy-size;
    @extend .ff-mark-bold;
}




.header-container{
    font-size: 16px;
    .nav{
        font-size: 16px;
        .submenu{
            font-size: 16px;
        }
    }
}


.contentBlock-imageQuote{
    .contentBlock-imageQuote-blockquote{
        @extend .small-header-size2;
        letter-spacing: -0.05em;
        cite{
            @extend .bodycopy-size;
        }
    }
}

.homeSection-CTA{
    a{
        @extend .small-header-size2;
        letter-spacing: -0.05em;
    }
}

.homeSection-header,
.introSlider-contentSets-set-header h1,
.introSlider-contentSets-set-header p{
    @extend .large-header-size;
    letter-spacing: -0.05em;
    line-height: 1em !important;
    margin-bottom: 0px;
}
@media (max-width: $first-breakpoint) {
    .homeSection-header,
    .introSlider-contentSets-set-header h1,
    .introSlider-contentSets-set-header p{
        font-size: 26px !important;
    }
}
.homeSection-subheader{
    @extend .subheader-size;
}
.introSlider-contentSets-set-header h1{
    color: $pink !important;
}
.introSlider-contentSets-set-header p{
    color: #ffffff !important;
}
.introSlider-content-slider-label{
    @include fp(font-size,14,16);
    @extend .ff-mark-bold;
}
.introSlider-contentSets-set-copy{
    @extend .ff-mark-bold;
}

.languageSelector{
    font-size: 12px;
}


.blogPost-title,
.blogArticle .contentBlock-quote .quote-holder .contentBlock-quote-blockquote,
.blogArticle .contentBlock-header .contentBlock-header-header{
    @extend .small-header-size;
}
.blogPost-author{
    @extend .subheader-size;
}
.blogPost{
    .aside{
        @extend .ff-mark;
    }
}



.featuredBlock-linkBlock{
    .linkBlock-content-heading{
        @extend .small-header-size;
        letter-spacing: -0.05em;
    }
    .linkBlock-content-subheading{
        @extend .smallcopy-size;
    }
    .linkBlock-content-copy{
        @extend .small-header-size;
        letter-spacing: -0.05em;
    }
    .tags{
        @extend .smallcopy-size;
    }
}

.contentBlock-caseStudyHeader{
    h1{
        @extend .huge-header-size;
    }
}

.caseStudyList-nav-header,
.ourTeamList-nav-header,
.ourBlogList-nav-header,
.education-nav-header{
    @extend .large-header-size;
    letter-spacing: -0.05em;
}
.caseStudyList-nav-nav,
.ourTeamList-nav-nav,
.aboutServices-nav,
.caseStudyList-intro-header{
    @extend .subheader-size;
}


.contentBlock-caseStudyIntro{
    .contentBlock-caseStudyIntro-copy{
        @extend .large-subheader-size;
    }
    .caseStudyIntroColumn-title{
        @extend .subheader-size;
    }
    .caseStudyIntroColumn-copy{
        @extend .ff-mark;
        @extend .bodycopy-size;
    }
    .copy-holder{
        @extend .large-subheader-size;
    }
    .overview-title{
        @extend .ff-mark-bold;
        @extend .subheader-size;
        color: $grey-text;
        margin-bottom: 20px;
    }
}

.contentBlock-quote {
    .quote-holder {
        .contentBlock-quote-blockquote {
            @extend .header-size;
            letter-spacing: -0.05em;
            .quotation-mark {}
            cite {
                @extend .subheader-size;
            }
        }
    }
}
.contentBlock-section-cb{
    .contentBlock-quote{
        .quote-holder {
            .contentBlock-quote-blockquote {
                @extend .large-subheader-size;
                letter-spacing: -0.05em;
                .quotation-mark {}
                cite {
                    @extend .subheader-size;
                }
            }
        }
    }
}
.bg--black, .page-ourTeam, .page-ourTeamList, .page-education{
    .quote-holder{
        cite{
            p{
                color: $grey-text;
                span{
                    color: #ffffff;
                }
            }
        }
    }
}

.contentBlock-copy {
    @extend .ff-mark;
    .contentBlock-copy-title {
        @extend .ff-mark-bold;
        @extend .subheader-size;
    }
}

.contentBlock-header {
    .contentBlock-header-header {
        @extend .header-size;
        letter-spacing: -0.05em;
    }
}

.contentBlock-podcast{
    .podcast-title{
        h1{
            @extend .header-size;
        }
    }
    .podcast-author,
    .podcast-author p{
        @extend .subheader-size;
    }
    .podcast-time,
    .podcast-time p{
        @extend .subheader-size;
    }
    .podcast-play-icon{
        display: block;
        width: 50px;
        svg{
            width: 100%;
        }
    }
}

.bookRecommendations{
    .bookRecommendations-header{
        @extend .subheader-size;
        letter-spacing: -0.05em;
    }
    .book-link{
        font-size: 12px;
        @extend .ff-mark;
    }
    .book-title{
        @extend .ff-mark-bold;
    }
    .book-author{
        @extend .ff-mark;
    }
}

.doNextLinks{
    .doNextLinks-header{
        @extend .header-size;
    }
}

.contentBlock-timeline {
    .timeline-header{
        h2 {
            @extend .subheader-size;
            color: $pink;
        }
        p{
            @extend .header-size;
        }
    }
    .timeline-text{
        p{
            @extend .ff-mark;
        }
    }
}

.pageContentBlock{
    .pageContentBlock-header{
        @extend .large-header-size;
    }
    .pageContentBlock-subtitle{
        @extend .subheader-size;
    }
    .pageContentBlock-col--first .pageContentBlock-text.largeCopy{
        p:first-child{
            @extend .small-header-size;
        }
    }
    .pageContentBlock-col--last{
        .pageContentBlock-text, p{
            @extend .ff-mark;
        }
    }
}

.formHolder{
    .formHolder-header{
        @extend .large-header-size;
        margin-bottom: 80px;
    }
    .addressHolder{
        @extend .ff-mark;
        p{
            @extend .ff-mark;
        }
        h5{
            @extend .ff-mark-bold;
        }
    }
    .socialLinks{
        @extend .ff-mark;
    }
}

.small-copy{
    @extend .ff-mark;
    p{
        @extend .ff-mark;
    }
}


.footer-container{
    @extend .ff-mark;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    h1,h2,h3,h4,h5,h6{
        @extend .ff-mark;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0px;
    }
    .footerCols-terms,
    .footerCols-copyright,
    .footerCols-credit{
        font-size: 12px;
        line-height: 18px;
    }
}

.homeSection-CTA{
    a{
        @extend .largeLink-size;
        letter-spacing: -0.05em;
        @extend .ff-mark-bold;
    }
}

.profileBlock{
    .profileBlockContent{
        .profileBlock-title{
            @extend .large-header-size;
            line-height: 1.1em;
            letter-spacing: -0.05em;
        }
        .profileBlock-row{
            .profileBlock-col{}
            .profileBlock-textCol{
                p{}
                .person-details{
                    @extend .subheader-size;
                    line-height: 1.1em;
                    .name{

                    }
                    .role{
                        color: $grey-text;
                    }
                }
                .profileBlock-copy{
                    @extend .ff-mark;
                }
                .email-link-holder{
                    a{
                    }
                }
            }
        }
    }
}

.pageContentBlock-link, .nextSectionLink, .featuredBlock-footerLink-link, .header-link,
.page-ourBlogList .caseStudyHeader-text-tags, .page-themeList .caseStudyHeader-text-tags{
    @extend .subheader-size;
    line-height: 1.1em;
}


.blogNav-header{
    font-size: 60px;
    line-height: 1.1em;
    letter-spacing: -0.05em;
}

@media (max-width: $menu-breakpoint) {
    .blogNav-header{
        font-size: 40px;
        margin-top: 10px !important;
    }
}
@media (max-width: $first-breakpoint) {
    .blogNav-header-holder{
        width: 100% !important;
        pointer-events: none;
    }
    .blogNav-header{
        display: inline-block;
        pointer-events: auto;
        margin-top: -5px !important;
    }
    .blogNavHolder .blogNav .blogNav-header-holder .blogNav-header-iconHolder{
        top: 12px;
        right: 50px;
        left: unset;
        pointer-events: auto;
    }
}
@media (max-width: $mobile-breakpoint) {

}


.educationSection-header{
    margin-top: 40px;
    padding-bottom: 20px;
    @extend .large-header-size;
    line-height: 1.1em;
    letter-spacing: -0.05em;
}
.education-nav-nav{
    @extend .subheader-size;
}

.testimonial-section{
    .quotation-mark{
        @include fp(font-size,80,120);
        color: $grey-text;
    }
    .contentBlock-quote-blockquote{
        @extend .large-subheader-size;
        line-height: 1.1em;
        letter-spacing: -0.05em;
        cite{
            display: block;
            padding-top: 40px;
            @extend .subheader-size;
            line-height: 1.1em;
        }
    }
    .quotation-mark{
        pointer-events: none;
    }
    .quote-finish-holder .quotation-mark{
        margin-top: -80px;
    }
}


.introSlider-contentSets-set-cta{
    margin-top: 20px;
    @extend .subheader-size;
    line-height: 1.1em;
    a{
        text-decoration: none;
        color: $pink !important;
    }
}

.footerCallout-block-copy{
    @extend .ff-mark;
}

.blogNav-tab-content{
    h1{
        @extend .small-header-size;
    }
}
