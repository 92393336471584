/* ==========================================================================
 Our Team
 ========================================================================== */

.profileBlock{
    //display: table-cell;
    vertical-align: middle;
    .profileBlockContent{
        .profileBlock-title{
            padding-top: 80px;
            padding-bottom: 40px;
            margin:0px;
            @media (max-width: $mobile-breakpoint) {
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }
        .profileBlock-row{
            .profileBlock-col{}
            .profileBlock-textCol{
                p{}
                .name{}
                .role{}
                .profileBlock-copy{
                    padding-bottom: 40px;
                    p{

                    }
                    p:last-child{
                        margin:0px;
                    }
                }
                .email-link-holder,
                .phone-link-holder{
                    a{
                        text-decoration: none;
                        color: #ffffff;
                        font-size: 18px;
                        white-space: nowrap;
                    }
                }
                .email-link-holder{
                    padding-top: 40px;
                    padding-bottom: 10px;
                }
                .phone-link-holder{
                    padding-bottom: 40px;
                }
            }
            .profileBlock-imageCol{
                display: none;
                padding-bottom: 40px;
                img{
                    width:100%;
                }
            }
        }
    }
    .socialLinks{
        font-size: 20px !important;
    }
}
@media (min-width: 960px) {
    .profileBlock{
        .profileBlockContent{
            .profileBlock-row{
                .profileBlock-textCol{
                    .email-link-holder,
                    .phone-link-holder{
                        width: 50%;
                        float: left;
                        padding-top: 40px;
                        padding-bottom: 40px;
                    }
                }
            }
        }
    }
}

.mobilePortrait{
    margin-top: 20px;
    margin-bottom: 40px;
}

@media (min-width: $first-breakpoint) {
    .mobilePortrait{
        display: none !important;
    }
    .profileBlock-imageCol{
        display: block !important;
    }
}


.ourTeamGrid{
    margin-top: 75px;
    margin-bottom: 20px;
    .featuredBlock-ourTeamGrid{
        margin-bottom: 40px;
    }
}
.ourTeamList-nav{
    .centreLock-margin{
        padding-bottom: 20px;
        border-bottom: 1px dotted #ffffff;
    }
    .ourTeamList-nav-header{
        margin-bottom: 20px;
    }
    .ourTeamList-nav-nav{
        //display: none;
        list-style: none;
        margin: 0px;
        width: 75%;
        li{
            display: inline-block;
            margin: 0px;
            a{
                text-decoration: none;
                color: $grey-text;
            }
            a:hover,a.selected{
                color: #ffffff;
            }
        }
        .nav-separator{
            padding-left: 1px;
            padding-right: 1px;
        }
    }
    .catSelected{
        .nav-separator{
            color: $grey-text;
        }
    }
    .allSelected{
        li a{
            color: #ffffff;
        }
    }
}
/*@media (min-width: $menu-breakpoint) {
    .ourTeamList-nav{
        .ourTeamList-nav-nav{
            display: block;
        }
    }
}*/

@media (max-width: $menu-breakpoint){
    .ourTeamList-nav{
        .centreLock-margin{
            padding-bottom: 0px;
        }
    }
}

.teamGrid{
    padding-top: 60px;
    padding-bottom: 80px;
    .teamGrid-content{
        width: 100%;
        overflow-x: hidden;
        min-height: 68vh;
        .teamGrid-block{
            width: calc(100% + 18px);
        }
    }
    .teamGrid-block-link{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        overflow: hidden;
        z-index: 1;
        margin-bottom: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}

.teamGrid-block-link{
    .teamGrid-block-image{
        width: 100%;
        height: auto;
        filter: grayscale(100%);
        @include opacity(0.6);
        will-change: filter, opacity;
        transition: filter 0.2s ease, opacity 0.2s linear;
    }
    .teamGrid-block-details{
        display: block;
        position: absolute;
        bottom: -14px;
        left: 10px;
        z-index: 5;
        transform: rotate(-90deg) translateZ(0px);
        transform-origin: left top 0;
        float: left;
        height: 32px;
        @include opacity(1);
        pointer-events:none;
        will-change: opacity;
        transition: opacity 0.2s linear;
        padding-right: 16px;
        .teamGrid-block-name{
            width: 100%;
            color: #ffffff !important;
            @include fp(font-size,16,18);
            line-height: 1.1em;
            display: inline-block;
        }
        .teamGrid-block-role{
            width: 100%;
            color: $black !important;
            @include fp(font-size,16,18);
            line-height: 1.1em;
            display: inline-block;
        }
    }
    .teamGrid-blockContent{
        position: relative;
    }
}
.teamGrid-block-link:hover{
    .teamGrid-block-image{
        filter: grayscale(0%);
        @include opacity(1);
        transition: filter 0.5s ease, opacity 0.2s linear;
    }
    .teamGrid-block-details{
        @include opacity(0);
    }
}

@media (min-width: $mobile-breakpoint) {
    .teamGrid{
        .teamGrid-block-link{
            width: 50%;
        }
    }
}
@media (min-width: 800px) {
    .teamGrid{
        .teamGrid-block-link{
            width: 25%;
        }
    }
}

@media (max-width: $first-breakpoint) {
    .teamGrid{
        padding-top: 30px;
    }
}
