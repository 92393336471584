/* ==========================================================================
 Content Blocks
 ========================================================================== */
.contentBlock {
    padding-top: 15px;
    padding-bottom: 55px;
    position: relative;
    .columns {
        position: relative;
    }
    img {
        width: 100%;
    }
}

.section{
    //display: table;
    position: relative;
    width: 100%;
    height: 100%;
    .contentBlock {
        height: 100%;
        .contentBlockContent {
            height: 100%;
        }
    }
}
.fp-enabled{
    .section{
        //min-height: 100vh;
    }
    .section-footerCalloutBlock, .fp-auto-height{
        min-height: 50vh;
    }
    .fp-auto-height.fp-hide{
        min-height: 0px;
        min-height: unset;
    }
    .section.image-section, .section.videoOverlay-section, .section.quote-section,
    .section.timeline-section, .section1x1-section, .section2x1-section, .section1x2-section, .section2x2-section,
    .section.map-section{
        min-height: unset !important;
    }
}
.fp-responsive .section{
    height: auto !important;

    .contentBlock-image,
    .contentBlock-gallery,
    .contentBlock-graph,
    .contentBlock-podcast,
    .contentBlock-timeline,
    .contentBlock-copy,
    .contentBlock-header,
    .contentBlock-imageQuote,
    .contentBlock-quote,
    .contentBlock-video,
    .contentBlock.video-block,
    .contentBlock-caseStudyIntro,

    .contentBlock-section-cb,
    .contentBlock-section-col{
        height: auto !important;
        min-height: unset !important;
        .contentBlockContent {
            height: auto !important;
            min-height: unset !important;
        }
    }
    .contentBlock-section-cb-videoLoop,
    .contentBlock-videoLoop{
        height: 50% !important;
        min-height: 50vh !important;
    }
    .contentBlock-timeline{
        padding-bottom: 40px !important;
    }
}
.fp-responsive .videoOverlay-section .video-block
.content-block-content .video--overlay .videoThumbnail{
    height: auto !important;
    .videoThumbnail-image{
        width: 100% !important;
        height: auto !important;
        object-fit: unset;
        font-family: unset;
    }
}
.vp-short .section{
    .contentBlock-section-cb-videoLoop,
    .contentBlock-videoLoop{
        height: 100% !important;
        min-height: 100vh !important;
    }
    .contentBlock-section-cb-header,
    .contentBlock-header,
    .contentBlock-section-cb-quote,
    .contentBlock-quote,
    .contentBlock-section-cb-copy,
    .contentBlock-copy{
        min-height: 105vh !important;
    }
    .contentBlock-caseStudyIntro{
        min-height: 150vh !important;
    }


    .mobileContentSlider{
        height: auto !important;
    }
    .mobileContentSlide{
        height: auto !important;
        .centreLock{
            height: auto !important;
            .copy-holder{
                max-height: unset !important;
            }
        }
    }

    .contentBlock-caseStudyIntro,
    .contentBlock-caseStudyIntro .contentBlockContent,
    .mobileContentSlide.contentBlock-caseStudyIntro-cols{
        min-height: 150vh !important;
    }
}
.no-ie.no-touch .section{
    transform: translate3d(0,0,0);
}


/* Header */
.contentBlock-header {
    .contentBlockContent{
        width: 70%;
        margin: auto;
        padding-top: 40px;
        padding-bottom: 60px;
        .contentBlock-header-header {
            margin: 20px 0px 20px 0px;
        }
    }
}
@media (max-width: $first-breakpoint - 1) {
    .contentBlock-header {
        .centreLock {
            //margin-top: 0px !important;
        }
    }
}
/* Header inside multiblock */
.contentBlock-section-cb{
    .contentBlock-header{
        overflow: hidden;
        //display: table;
        .centreLock{
            //display: table-cell;
            vertical-align: middle;
            height: auto !important;
            .centreLock-margin{
                height: auto !important;
                //display: table;
            }
        }
        .contentBlockContent{
            height: auto !important;
            position:relative;
            //display: table-cell;
            vertical-align: middle;
            .contentBlock-header-header{
                position: relative;
                //transform: translateY(-50%);
                //top: 50%;
            }
        }
    }
}

/* Case Study Header */
.contentBlock-caseStudyHeader {
    height: 100% !important;
    //min-height: 100vh !important;
    .contentBlock-caseStudyHeader-image{
        width: 100%;
        height: 100% !important;
        overflow: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        @extend .obj-cover;
        z-index: 0;
        //pointer-events: none;
    }
    .contentBlock-caseStudyHeader-text{
        padding-top: 40px;
        position: relative;
        top: 0px;
        z-index: 1;
        h1{
            padding-top: 10px;
            width: 90%;
        }
    }
}
.section.caseStudyHeader-section{
    //height: 100vh !important;
    .contentBlock.contentBlock-caseStudyHeader{
        height: 100% !important;
        .contentBlockContent {
            height: 100% !important;
        }
    }
}

@media (min-width: $first-breakpoint) {
    .contentBlock-caseStudyHeader {
        .contentBlock-caseStudyHeader-text{
            h1{
                width: 80%;
            }
        }
    }
}

/* Case Study Intro */
.contentBlock-caseStudyIntro {
    padding-bottom: 25px;
    background: #ffffff;
    color: $black;
    height: 100%;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $black;
    }
    .contentBlock-caseStudyIntro-copy {
        .copy-holder {
            margin: 40px;
            padding: 20px 0px 20px 0px;
            box-sizing: border-box;
            max-height: 75vh;
            p:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .contentBlock-caseStudyIntro-cols {
        width:100%;
        background-color: $grey;
        padding-bottom: 40px;
        //display: table;
        .centreLock{
            //display: table-cell;
            vertical-align: middle;
            background-color: $grey;
        }
        .caseStudyIntroColumn {
            margin-top: 39px;
            padding-right: 30px;
            .arch-small-svg {
                width: 22px;
                height: 22px;
                padding-right: 10px;
                path{
                    fill: $red;
                }
            }
            .caseStudyIntroColumn-title {
                margin-bottom: 10px;
            }
            .caseStudyIntroColumn-copy {
                margin-bottom: 0px;
            }
        }
        .caseStudyIntroColumn--first {
        }
        .caseStudyIntroColumn--last {}
    }
}

@media (min-width: $first-breakpoint) {
    .contentBlock-caseStudyIntro {
        .contentBlock-caseStudyIntro-copy {
            .copy-holder {
                width:82.6666666667%;
                margin: auto;
            }
        }
        .contentBlock-caseStudyIntro-cols {
            .caseStudyIntroColumn {
                padding-left: 20px;
                padding-right: 50px;
                border-left: 1px dotted $black;
            }
            .caseStudyIntroColumn--first {
                border-left: none;
            }
        }
    }
    .fp-enabled{
        .contentBlock-caseStudyIntro {
            .contentBlock-caseStudyIntro-cols {
                position: absolute;
                bottom: 0px;
                box-sizing: border-box;
                min-height: 25vh;
            }
        }
    }
    .no-fp{
        .contentBlock-caseStudyIntro {
            .contentBlock-caseStudyIntro-copy {
                .copy-holder {
                    padding-top:10px;
                    padding-bottom:40px;
                }
            }
        }
    }
}

.fp-responsive .caseStudyIntro-holder{
    .copy-holder{
        min-height: 100vh;
    }
}

.caseStudyIntro-holder-mobile{
    display: none;
    .flickity-page-dots{
        bottom: 30px;
        .dot{
            background: $grey-text;
        }
    }
    .caseStudyIntroRow{
        margin-top: 80px;
    }
    .copy-holder{
        //margin-top: 60px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}
@media (max-width: $first-breakpoint) {
    .caseStudyIntro-holder{
        display: none;
    }
    .caseStudyIntro-holder-mobile{
        display: block;
    }
}


/* Copy */
.contentBlock-copy {
    .contentBlockContent{
        padding-top: 20px;
        padding-bottom: 20px;
        max-width: 750px;
        .contentBlock-copy-title {
            margin-bottom: 20px;
        }
        .contentBlock-copy-text {
            p:last-child{
                margin-bottom: 0px;
            }
        }
    }
}
.contentBlock-copy.bg--grey {
    .contentBlock-copy-title {
        color: $grey-text;
    }
    .contentBlock-copy-text {
        color: $black;
        p{
            color: $black;
        }
    }
}
@media (max-width: $first-breakpoint - 1) {
    .contentBlock-copy {
        .centreLock {
            margin-top: 0px !important;
        }
    }
}
/* Copy inside multiblock */
.contentBlock-section-cb{
    .contentBlock-copy{
        //overflow: hidden;
        .contentBlockContent{
            width: 66%;
            margin: auto;
            position:relative;
            //display: table;
            .contentBlock-copy-copy{
                position: relative;
                //transform: translateY(-50%);
                //top: 50%;
                //display: table-cell;
                vertical-align: middle;
                padding: 20px 0px 20px 0px;
            }
        }
    }
}
@media (max-width: $first-breakpoint - 1) {
    .contentBlock-section-cb{
        .contentBlock-copy{
            .contentBlockContent{
                width: 100%;
            }
        }
    }
}


/* Quote */
.contentBlock-quote {
    height: 100%;
    .quote-holder {
        width: 85%;
        margin: auto;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        .contentBlock-quote-blockquote {
            margin: 0px;
            .quotation-mark {
                display:none;
            }
            cite {
                display: block;
                padding-top: 20px;
                margin: 0px;
                p{
                    color: $pink;
                    span{
                        color: #ffffff;
                    }
                }
            }
        }
    }
}
@media (min-width: $first-breakpoint) {
    .contentBlock-quote {
        .quote-holder {
            width: 66%;
        }
    }
}
@media (max-width: $first-breakpoint - 1) {
    .contentBlock-quote {
        .centreLock {
            //margin-top: 0px !important;
        }
    }
}
.quote-section{
    //height: 100vh;
    overflow: hidden;
    .fp-scroller{
        height: 100%;
    }
}
/* Quote inside multiblock */
.contentBlock-section-cb{
    .contentBlock-quote{
        //display: table;
        .contentBlockContent{
            //display: table-cell;
            vertical-align: middle;
            height: auto !important;
            .centreLock, .centreLock-margin{
                height: auto !important;
            }
            .centreLock{
                //display: table;
            }
            .centreLock-margin{
                //display: table-cell;
                vertical-align: middle;
            }
            .quote-holder{
                text-align: left;
                margin-top: 30px;
                position: relative;
                //top: 50%;
                //transform: translateY(-50%);
            }
        }
    }
}
.fp-responsive .contentBlock-section-cb{
    .contentBlock-quote{
        .quote-holder{
            margin-top: 30px;
        }
    }
}

.blogPost{
    .contentBlock-quote{
        color: $black;
        blockquote{
            color: $black;
        }
    }
}

.blogPost .contentBlock-quote{
    .quote-holder {
        text-align: left;
    }
}


.blogPost .contentBlock-quote,
.contentBlock-quote.bg--grey{
    .quote-holder {
        .contentBlock-quote-blockquote {
            color: $black  !important;
            line-height: 1.1em;
            cite {
                p{
                    color: $grey-text  !important;
                    span{
                        color: $black  !important;
                    }
                }
            }
        }
    }
}

.contentBlock-quote.bg--black{
    .quote-holder {
        .contentBlock-quote-blockquote {
            color: #ffffff  !important;
            cite {
                p{
                    color: $grey !important;
                    span{
                        color: #ffffff  !important;
                    }
                }
            }
        }
    }
}


/* Image and Quote */
.contentBlock-imageQuote {
    width: 100%;
    .contentBlock-imageQuote-image {}
    .quote-holder{
        .contentBlock-imageQuote-blockquote {
            padding: 10px;
            line-height: 1.1em;
            margin: 0px;
            .quotation-mark {display:none;}
            p{
                display: inline;
            }
            cite {}
        }
    }
    .contentBlockContent{
        width: 100%;
    }
    .top-mobile-align {
        display: table;
        .quote-holder {
            display: table-header-group;
        }
        .contentBlock-imageQuote-image {
            display: table-footer-group;
            //pointer-events: none;
        }
    }
}

@media (min-width: $first-breakpoint) {
    .contentBlock-imageQuote {
        .contentBlock-imageQuote-image {
            width: 70%;
        }
        .quote-holder {
            position: absolute;
            top: 50%;
            right: 0px;
            width: 50%;
            transform: translateY(-50%);
            .contentBlock-imageQuote-blockquote {
                .quotation-mark {}
                cite {}
            }
        }
        .left-align{
            img{
                float: right;
            }
            .quote-holder{
                left: 0px;
                right: inherit;
            }
        }
    }
}


/* Image */
.contentBlock-image {
    display: block;
    width: 100%;
    .contentBlock-image-image {
        display: block;
        width: 100%;
        //pointer-events: none;
    }
    .centre-align {
        margin: auto;
    }
    .left-align {
        float: left;
    }
    .right-align {
        float: right;
    }
    .half-width {
        width: 50%;
    }
    .fullbleed {
        .contentBlock-image-image {
            width: 100%;
            height: 100%;
            display: block;
            @extend .obj-cover;
        }
    }
}

.page-blogs .contentBlock-image .half-width{
    @media (max-width: $mobile-breakpoint) {
        width: 100%;
    }
}

.fp-enabled{
    .contentBlock-image{
        //height: 100vh;
    }
}
.contentBlock-section-cb{
    .contentBlock-image {
        height: 100%;
        .contentBlockContent{
            position: absolute;
            width: 100%;
            height: 100%;
        }
        .fullbleed {
            .contentBlock-image-image {
                width: 101% !important;
                height: 101% !important;
            }
        }
    }
}
.fp-responsive .contentBlock-image{
    .fullbleed{
        position: relative !important;
        .contentBlock-image-image{
            width: 100% !important;
            height: auto !important;
            object-fit: unset;
            font-family: unset;
        }
    }
}


/* Gallery */
.contentBlock-gallery {
    .gallery{
        position: relative;
        width: 100%;
        width: 100vw;
        height: 100%;
        //height: 100vh;
        .gallerySlide{
            width:100%;
            height: 100%;
            img{
                @extend .obj-cover;
            }
        }
    }
}
.gallery-section{
    overflow-x: hidden;
    .fp-scroller{
        height: 100%;
    }
}
.fp-responsive .contentBlock-gallery{
    .gallery{
        height: 50vh;
    }
}
.vp-short .fp-responsive .contentBlock-gallery{
    .gallery{
        height: 110vh;
    }
}


/* Gallery */
.gallery {
    img {
        display: block;
        float: left;
    }
}

.contentBlock-gallery {
    .gallery {
        .contentBlock-image-image {}
    }
}


/* Podcast */
.contentBlock-podcast {
    .contentBlockContent{
        .contentBlock-podcast-content{
            width: 66%;
            margin: auto;
            padding-top: 40px;
            padding-bottom: 40px;
            .podcast-icon{
                width: 40px;
                padding-bottom: 10px;
                svg{
                    width: 100%;
                }
            }
            .podcast-title{
                text-decoration: none;
                color: #ffffff;
            }
            .podcast-details{
                display: block;
                clear: both;
                width: 100%;
                .podcast-author{
                    display: inline-block;
                    width: 45%;
                    float: left;
                    color: $pink;
                    a{
                        text-decoration: none;
                        color: $pink;
                    }
                }
                .podcast-time{
                    display: inline-block;
                    width: 45%;
                    float: right;
                    text-align: right;
                    p{
                        padding-right: 40px;
                        color: $pink !important;
                    }
                }
            }
            .podcast-play-icon{
                display: block;
                width: 60px;
                svg{
                    width: 100%;
                }
            }
        }
    }
}
.fp-responsive .contentBlock-podcast {
    .contentBlockContent{
        .contentBlock-podcast-content{
            width: 80%;
        }
    }
}
@media (max-width: $first-breakpoint - 1) {
    .contentBlock-podcast {
        .centreLock {
            margin-top: 0px !important;
        }
    }
}



/* Section */
.contentBlock-section {
    .contentBlockContent{
        width: 100%;
        height: 100%;
    }
    .contentBlock-section-content {
        width: 100%;
        height: 100%;
        .contentBlock-section-col {
            width: 100%;
            height: 100%;
            float: left;
            .contentBlock-section-cb {
                position: relative;
                height: 50%;
                min-height:50vh;
                .contentBlock{
                    height: 100%;
                    min-height: 50vh;
                    box-sizing: border-box;
                    .contentBlockContent{
                        box-sizing: border-box;
                    }
                }
            }
        }
        .contentBlock-section-col--first {}
        .contentBlock-section-col--last {}
        .contentBlock {
            padding: 0px !important;
        }
    }
}
.contentBlock-section--1-2{
    .contentBlock-section-content {
        .contentBlock-section-col--first {
            .contentBlock-section-cb {
                height: 100%;
                //min-height:100vh;
                .contentBlock{
                    height: 100%;
                    //height: 100vh;
                    //min-height: 100vh;
                }
            }
        }
    }
}
.contentBlock-section--2-1{
    .contentBlock-section-content {
        .contentBlock-section-col--last {
            .contentBlock-section-cb {
                height: 100%;
                height: 100%;
                //min-height:100vh;
                .contentBlock{
                    height: 100%;
                    //height: 100vh;
                    //min-height: 100vh;
                }
            }
        }
    }
}
.contentBlock-section--1-1{
    .contentBlock-section-content {
        .contentBlock-section-col--first,
        .contentBlock-section-col--last {
            .contentBlock-section-cb {
                height: 100%;
                height: 100%;
                //min-height:100vh;
                .contentBlock{
                    height: 100%;
                    //height: 100vh;
                    //min-height: 100vh;
                }
            }
        }
    }
}
.section-section{
    .fp-scroller{
        //min-height: 100%;
    }
}
.contentBlock-section-cb{
    .centreLock, .centreLock-margin{
        min-height: 100%;
        //margin: 0px;
    }
}

@media (min-width: $first-breakpoint) {
    .contentBlock-section {
        .contentBlock-section-content {
            .contentBlock-section-col {
                width: 50%;
                .contentBlock-section-cb {}
            }
            .contentBlock-section-col--first {}
            .contentBlock-section-col--last {}
        }
    }
}


/* Timeline */
.contentBlock-timeline {
    .timeline-header{
        h2 {
            margin-bottom: 10px;
            padding-top: 40px;
        }
        p{
        }
    }
    .timeline-holder, .timeline-holder-mobile{
        padding-top: 20px;
        .timeline-slide{
            position: relative;
            width: 100%;
            box-sizing: border-box;
            display: inline-block;
            float: left;
            padding-right: 20px;
            margin-bottom: 40px;
            .timeline-year{
                position: absolute;
                top:0px;
                left: 0px;
                width: 75px;
                height: 75px;
                overflow: hidden;
                border: 10px solid #ffffff;
                box-sizing: border-box;
                p{
                    padding-top: 16px;
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    color: $pink;
                }
            }
            .timeline-text{
                margin-top: 65px;
                margin-left: 65px;
                padding: 20px 15px 30px 15px;
                border: 10px solid #ffffff;
                box-sizing: border-box;
                h3{
                    color: $pink;
                    margin-bottom: 15px;
                }
                p{

                }
                p:last-child{
                    margin: 0px;
                }
            }
        }
    }
}
.timeline-holder-mobile{
    display: none;
    .flickity-page-dots{
        bottom:0px;
    }
}
@media (max-width: $first-breakpoint) {
    .timeline-holder{
        display: none;
    }
    .timeline-holder-mobile{
        display: block;
    }
}


@media (min-width: $first-breakpoint) {
    .contentBlock-timeline {
        .timeline-holder{
            .timeline-slide{
                width: 50%;
            }
        }
    }
}
@media (min-width: 1200px) {
    .contentBlock-timeline {
        .timeline-holder{
            .timeline-slide{
                width: 25%;
            }
        }
    }
}

@media (max-width: $first-breakpoint - 1) {
    .contentBlock-timeline {
        .centreLock {
            margin-top: 0px !important;
        }
    }
}

@media (max-width: $mobile-breakpoint) {
    .contentBlock-timeline {
        .centreLock {
            margin-top: 30px !important;
        }
    }
}


/* Dont have footer padding on CBs when in a scrolling slides section,
unless auto-height (or video) */
.section:not(.fp-auto-height) .contentBlock {
    padding: 0px;
}


/* Video */
.contentBlock-video {
    padding: 0px;
    .play-icon {
        width: 82px !important;
        height: 82px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //pointer-events: none;
    }
}
.videoOverlay-section{
    .fp-scrollable{
        height: 100%;
        overflow: hidden;
        .fp-scroller{
            height: 100%;
            overflow: hidden;
        }
    }
    .video-block{
        width: 100%;
        height: 100%;
        .content-block-content{
            width: 100%;
            height: 100%;
            .video--loop{
                //pointer-events: none;
            }
            .videoholder{
                width: 100%;
                height: 100%;
            }
            .video--overlay{
                .videoThumbnail{
                    width: 100%;
                    height: 100%;
                    height: 100vh;
                    top:0px;
                    left:0px;
                    display: block;
                    .videoThumbnail-image{
                        width: 100%;
                        height: 100%;
                        @extend .obj-cover;
                        display: block;
                    }
                    .playIcon{
                        position: absolute;
                        display: block;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
.contentBlock-section-cb{
    .contentBlock-video {
        .video--overlay {
            .videoThumbnail-image {
                width: 101% !important;
                height: 101% !important;
            }
        }
    }
}
.fp-responsive .contentBlock-video{
    .video--overlay{
        .videoThumbnail-image{
            width: 100% !important;
            height: auto !important;
            //object-fit: none;
        }
    }
}
.contentBlock-section-cb-image, .contentBlock-section-cb-videoLoop{
    overflow: hidden;
}

/* Graphs */
.contentBlock-graph{}

/* Pie Chart */
.contentBlock-graph-piechart{
    .graphContainer{
        max-width: 500px;
        margin: auto;
        margin-top: 60px;
    }
}

.covervid-video{
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
    @extend .obj-cover;
}


.microsite-block-gallery{
    margin:20px 0px;
}
.simple-gallery{
    width: 100%;
    .simple-gallerySlide{
        width: 80%;
        margin-right: 28px;
        max-width: calc(100vh * 1.6);
    }
}


.flickity-prev-next-button{
    width: 60px;
    height: 60px;
    @media (max-width: $first-breakpoint) {
        width: 40px;
        height: 40px;
    }
    background-color: transparent !important;
    .flickity-button-icon{
        @include opacity(0.75);
        transition: opacity 0.4s ease;
    }
    svg{
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }
}
.flickity-prev-next-button.next{
    @media (max-width: $first-breakpoint) {
        right: 11%;
    }
}
.flickity-prev-next-button.previous{
    @media (max-width: $first-breakpoint) {
        left: 11%;
    }
}
.bg--red .flickity-prev-next-button .flickity-button-icon path{
    fill: $red;
}
.bg--pink .flickity-prev-next-button .flickity-button-icon path{
    fill: $pink;
}
.bg--black .flickity-prev-next-button .flickity-button-icon path{
    fill: #ffffff;
}
.bg--white .flickity-prev-next-button .flickity-button-icon path{
    fill: $black;
}
.flickity-enabled:hover{
    .flickity-prev-next-button{
        .flickity-button-icon{
            @include opacity(1);
        }
    }
}

.fp-responsive .pageContentBlock-col-link{
    padding-bottom: 20px;
}
.pageContentBlock-col-links{
    @media (max-width: $first-breakpoint) {
        padding-bottom: 40px !important;
    }
}


.microsite-image.half-width{
    width: 50%;
}
