.page-themeList,
.page-ourBlogList,
.page-blogs{
    .contentBlock-caseStudyHeader{
        padding-top: 0px;
        padding-bottom: 2px;
    }
}
.page-themeList{
    .caseStudyGrid {
        margin-bottom: 0px;
    }
    .relevant-cases{
        padding-top: 40px;
        padding-bottom: 30px;
    }
}

.page-ourBlogList{
    .caseStudyHeader-text-title{
        h1{
            margin-bottom: 78px !important;
            padding-top: 15px !important;
            @media (min-width: $mobile-breakpoint) {
                margin-bottom: 28px !important;
            }
        }
    }
}

.blogThemeFeature{
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;

    @media (min-width: $mobile-breakpoint) {
        min-height: 46vh !important;
    }
    .centreLock-margin{
        display: flex;
        align-items: flex-end;
        min-height: 41vh;
        position: relative;
        @media (max-width: $mobile-breakpoint) {
            min-height: 100vh;
            align-items: flex-start;
        }
    }

    .strapline{
        display: block;
        margin-bottom: 18px;
    }
    h3{
        @extend .huge-header-size;
    }
    .featuredBlock-footerLink-link{
        display: block;
        margin-top: 38px;
        color: #ffffff !important;
        text-decoration: none !important;
    }

    .blogThemeFeature-title{
        text-decoration: none !important;
    }

    .blogThemeFeature-copy{
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;
        z-index:2;
    }

    .blogThemeFeature-image{
        z-index:1;
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0px;
        bottom: 0px;
        mix-blend-mode: darken;
        @media (max-width: $mobile-breakpoint) {
            width: 100%;
        }

        img{
            width: 100%;
            height: 100%;
            object-position: bottom;
            @extend .obj-cover;
        }
    }
}

.themedblog-header{
    padding-top: 90px;
    margin-top: 0px;

    .blogPost-details{
        display: block !important;
        @media (max-width: $mobile-breakpoint) {
            padding: 0px 12px;
        }
    }

    .blogPostHeader{
        padding-right: 20px;
    }

    &>.centreLock>.centreLock-margin>.row{
        @media (min-width: 740px) {
            display: flex;
        }
    }
    .aside{
        min-width: 140px;
    }
}

.blogPost-details{
    width: 100%;
    margin-top: 16px;
}
.blogPost-details--block{
    width: 100%;
    @media (max-width: 739px) {
        margin-left: 40px;
        width: 40%;
        width: calc(50% - 41px);
        @media (max-width: $mobile-breakpoint) {
            width: calc(50% - 31px);
            margin-left: 30px;
        }
        clear: none !important;
        display: inline-block;
        padding-bottom: 20px !important;
        .asideTags-tag-link.js-blogGoBack{
            display: block !important;
        }
        .blogPost-back{
            margin-top: 6px;
        }
    }
}

.portrait-image{
    max-width: 300px;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    img{
        display: block;
        width: 100%;
    }
    @media (min-width: 740px){
        margin-left: 10px;
        margin-right: 10px;
        max-width: unset;
    }
}

.testimonial-nb{
    padding-top: 40px;
    padding-bottom: 40px;
    min-height:50vh;
    display:flex;
    vertical-align: middle;
    text-align: left;
    overflow-x: hidden;
    .row{
        @media (min-width: 740px){
            display: flex;
        }
    }
    .three.columns{
        align-self: center;
    }
    .contentBlock-quote{
        @media (min-width: 740px) {
            margin-left: 10.6667% !important;
        }
    }

    .portrait-image{
        border-radius:100%;
        overflow:hidden;
        max-width: 280px;
        margin: auto;
    }
    .portrait-image img{
        width: 100%;
        height: auto;
        display: block;
    }
    .quote-holder blockquote.contentBlock-quote-blockquote{
        @extend .large-subheader-size;
        text-align: left;
        @media (min-width: 740px) {
            padding-right: 0px !important;
        }
    }
    .first-quote{
        width: auto !important;
        @media (min-width: 740px) {
            position: absolute;
            left: -12%;
        }
    }
    .quote-holder{
        position: relative;
        width: 100%;
    }
    .footerCallout-block-link{
        @extend .subheader-size;
        text-decoration: none;
        color: $grey-text !important;
        &:hover{
            color: #ffffff !important;
        }
    }
    .contentBlock-quote-cite{
        padding-top: 40px !important;
        text-align: left;
        @extend .subheader-size;
        p{
            color: #ffffff !important;
            span{
                color: $grey-text !important;
            }
        }
    }

    .quotation-markend{
        @media (max-width: 740px) {
            span{
                transform: none !important;
            }
        }
    }
}


.page-themeList{
    .theme-subtitle{
        h2{
            @extend .header-size;
        }
    }
    .theme-details{
        margin-top: 40px;
        margin-bottom: 20px;
        @media (max-width: $first-breakpoint){
            .columns{
                padding-left: 0px !important;
            }
        }
        p{
            @extend .largecopy-size;
            @extend .ff-mark;
        }

        h2{
            @media (max-width: $first-breakpoint){
                margin-top: 20px;
                position: unset !important;
                transform: none !important;
            }
        }
    }
    .download-tools{
        @extend .blogcta-size;
        a{
            color: $faded-pink !important;
            text-decoration: none !important;
            display: block;
            margin-bottom: 15px;
            transition: color 0.3s linear;
            &:hover{
                color: #ffffff !important;
            }
        }
        .toolIcon{
            display: inline-block;
            width: 26px;
            padding-right: 15px;
            vertical-align: middle;
            svg{
                height: 80%;
                transition: height 0.4s ease;
                path,circle,rect,polygon{
                    fill: #ffffff;
                }
                line{
                    stroke: #ffffff;
                }
            }
        }
        .download-tool{
            @media (max-width: $mobile-breakpoint){
                margin-bottom: 0px;
            }
            a{
                @media (max-width: $mobile-breakpoint){
                    pointer-events:none !important;
                }
            }
            &.is-selected{
                a{
                    pointer-events:auto !important;
                }
                svg{
                    height: 100%;
                }
            }
        }

        @media (max-width: $mobile-breakpoint){
            a{
                text-align: center;
                padding-bottom:20px;
                color: $pink !important;
                transition: color 0.3s linear;
            }
            .toolIcon{
                height: 90vw;
                max-height: 150px;
                width: 100%;
                margin:auto;
            }
            .download-tool{
                @include opacity(0.5);
                margin-bottom: 20px;
                width: 33%;
                transition: opacity 0.3s linear;
                nobr{
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    @include opacity(0,true);
                }
                &.is-selected{
                    a{
                        color: #ffffff !important;
                    }
                    @include opacity(1);
                    z-index: 2;
                    nobr{
                        @include opacity(1,true);
                    }
                }

            }
        }
    }
}


.header-link{
    color: #ffffff;
    display: inline-block;
    float: left;
    width: 33%;
    @media (max-width: $second-breakpoint) {
        width: 50%;
    }
    @media (max-width: $first-breakpoint) {
        width: 100%;
        margin-bottom: 20px;
    }
    a{
        color: #ffffff;
        &:hover{
            color: #ffffff;
        }
    }
}

.page-blogs-themed{
    .microsite-block{
        padding-top: 0px !important;
        padding-bottom: 45px !important;
        &.microsite-block-getInTouch{
            padding-bottom: 0px !important;
        }

        .copy{
            @extend .blogcopy-size;
            @extend .ff-mark;
            p{
                @extend .blogcopy-size;
                @extend .ff-mark;
            }
        }
        .cta-link{
            @extend .blogcta-size;
        }
        .subheader{
            @extend .blogtitle-size;
            h2{
                @extend .blogtitle-size;
            }
        }
    }


    .content-blocks .contentBlock {
        @extend .blogcopy-size;
        @extend .ff-mark;
        p{
            @extend .blogcopy-size;
            @extend .ff-mark;
        }
        .contentBlock-copy-title{
            @extend .blogtitle-size;
        }
    }
    .blogArticle{
        padding-bottom: 0px !important;
    }
    .pageContent-restyledBlog{
        .blogArticle .contentBlock{
            @extend .blogcopy-size;
            @extend .ff-mark;
            p{
                @extend .blogcopy-size;
                @extend .ff-mark;
            }
        }
        .blogArticle .content-blocks{
            padding-bottom: 30px !important;
        }
    }

    .bg--red{
        .authorDetails{
            color: #ffffff;
            a{
                color: #ffffff;
            }
        }
    }
}

.microsite-block, .themedblog-header{
    .blogPost-title{
        @extend .blogheader-size;
    }
    .subheader{
        @extend .blogtitle-size;
        h2{
            @extend .blogtitle-size;
        }
    }
    .blogPost-details{
        @extend .blogdetails-size;
        @extend .ff-mark;
    }
    .testimonial-section .contentBlock-quote-blockquote{
        @extend .blogquote-size;
    }
    .testimonial-section .contentBlock-quote-blockquote cite{
        @extend .blogcopy-size;
    }
}

.page-blogs-themed .pageContent-ourBlog .blogPost{
    .strapline{
        @extend .blogcopy-size;
    }
    .blogPost-authors, .blogPost-author{
        @extend .blogauthor-size;
    }
}

.inline-blog-signup{
    .signUpPrompt{
        h1{
            @extend .blogtitle-size;
        }
    }
}

.microsite-block{
    @media (max-width: $first-breakpoint) {
        .copy-holder{
            margin-bottom: 60px !important;
        }
    }
}

.page-blogs-themed{
    .quote-holder{
        @media (max-width: 740px) {
            display: block !important;
            .quotation-mark{
                position: relative !important;
            }
            .quotation-markend{
                padding-right: 60px;
                span{
                    transform: translateY(-150%);
                }
            }
            .contentBlock-quote-cite{
                padding-top: 80px;
            }
        }
    }
    .content-blocks.bg--white{
        p a{
            color: $red;
            &:hover{
                color: $pink;
            }
        }

        .cta-link{
            color: $grey-text !important;
            &:hover{
                color: $black !important;
            }
            .linkArrow .arrow{
                fill: $black !important;
            }
        }
    }
}


.microsite-block-textImage .secondcol{
    .copy-holder{
        @media (max-width: 740px) {
            padding-top: 20px;
        }
    }
}


.pageTopImageHeader{
    .contentBlockContent{
        height: 100vh;
    }
    .header-link{
        width: 100%;
    }
}

.themeContentGrid{
    min-height: unset !important;

    @media (max-width: $mobile-breakpoint) {
        .blogFeature-content .featuredBlock-content .featuredBlock-col .featuredBlock-linkBlock{
            .real-header, .linkArrow{
                display: block !important;
            }
            .fake-header{
                display: none !important;
            }
        }
    }
}


.pageTopImageHeader{
    .centreLock-margin{
        @media (max-width: $mobile-breakpoint) {
            display: flex;
            align-items: flex-start;
            min-height: 100vh;
            position: relative;
            .contentBlock-caseStudyHeader-text{
                padding-top: 220px !important;
            }
            .caseStudyHeader-text-title h1{
                margin-bottom: 28px !important;
            }
        }
    }
}
