/* ==========================================================================
 Home
 ========================================================================== */

/* Section */
.homeSection{
    //display: table;
    posisiton: relative;
    width: 100%;
    height: 100%;
    .centreLock{
        //display: table-cell;
        vertical-align: middle;
    }
    .homeSection-content{
        padding-top: 80px;
        padding-bottom: 40px;
        .homeSection-header{
            width: 75%;
            margin-bottom: 30px;
        }
        .homeSection-subheader{
            width: 75%;
            margin-bottom: 30px;
        }
    }
}
.fp-enabled{
    .homeSection{
        min-height: 100vh;
    }
}

@media (max-width: $mobile-breakpoint) {
    .homeSection .homeSection-content{
        padding-top: 20px;
    }
}

.homeSection-cols {
    .homeSection-content {
        width:100%;
        height:100%;
        posisiton: relative;
        .homeSection-col {
            width:50%;
            height:100%;
            float: left;
            .homeSection-col-block {
                width:100%;
                height:100%;
                display: block;
                height:50vh;
            }
        }
        .homeSection-col--first {}
        .homeSection-col--last {}
    }
}

.fp-responsive{
    .homeSection-cols {
        .homeSection-content {
            .homeSection-col {
                width: 100%;
                .homeSection-col-block {}
            }
            .homeSection-col--first {}
            .homeSection-col--last {}
        }
    }
}

.homeSection-CTA{
    text-align: center;
    a{
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        position: relative;
        span{
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0px);
        }
    }
    a.bg--grey{
        color: $grey-text;
    }
    .homeSection-content{
        padding: 0px;
    }
}

@media (max-width: $mobile-breakpoint) {
    .homeSection-CTA{
        .homeSection-content{
            .homeSection-col{
                width: 100% !important;
                .homeSection-col-block{
                    height: 25vh;
                }
            }
        }
    }
}

.no-touch .homeSection-CTA .homeSection-content:hover{
    a span{
        @include opacity(0.3);
    }
}
.no-touch .homeSection-CTA .homeSection-content{
    a span{
        transition: opacity 0.3s linear;
    }
    a:hover span{
        @include opacity(1);
    }
}

.bg--grey{
    .homeSection-content{
        color: $black;
        .homeSection-header, .homeSection-subheader{
            color: $black;
        }
    }
}

.homeSection-content--copy .featuredBlock-footerLink{
    margin-top: 20px;
    display: none !important;
}
.featuredBlock-home{
    width: 100%;
}

@media (min-width: $mobile-breakpoint) and (max-width: $first-breakpoint) {
    .homeSection{
        padding-bottom: 66px !important;
        .homeSection-content{
            padding-top: 50px !important;
        }
    }
    .featuredBlock-home .featuredBlock-footerLink{
        display: none !important;
    }
    .homeSection-content--copy .featuredBlock-footerLink{
        display: block !important;
    }
    .homeSection-content--copy,.featuredBlock-home{
        display: inline-block;
        float: left;
        width: 50% !important;
    }
    .featuredBlock-home{
        .featuredBlock-col,
        .featuredBlock-cols-mobile .featuredBlock-col{
            width: 100% !important;
        }
    }

}

.homeSection .featuredBlock-cols-mobile .flickity-page-dots{
    bottom: 20vh;
    left: 0px;
    position: fixed;
}
.page-blogs .homeSection .featuredBlock-cols-mobile .flickity-page-dots,
.page-blogs .homeSection .featuredBlock-cols .flickity-page-dots{
    position: initial !important;
}


.homeSection.homeSection-CTA{
    padding-bottom: 0px !important;
}
