/* ==========================================================================
 Utilities
 ========================================================================== */

.ie img{
  -ms-interpolation-mode: bicubic;
}
.accelerated {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.backface{
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-transform-style: flat;
  -moz-transform-style: flat;
  -ms-transform-style: flat;
  -o-transform-style: flat;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  text-indent: -9999px;
}
.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.real-hidden{
  display: none;
}
.u-full-width{
  width: 100%;
  box-sizing: border-box;
}
.u-max-full-width{
  max-width: 100%;
  box-sizing: border-box;
}
.u-pull-right{
  float: right;
}
.u-pull-left{
  float: left;
}
@media (max-width: $first-breakpoint) {
  .first-breakpoint-hide{
    display: none !important;
  }
}
@media (max-width: $menu-breakpoint) {
  .menu-breakpoint-hide{
    display: none !important;
  }
}
@media (max-width: $second-breakpoint) {
  .second-breakpoint-hide{
    display: none !important;
  }
}
.no-scroll{
  overflow: hidden !important;
}
.force-reveal{
  .reveal{
    visibility: visible !important;
    -webkit-transform: translateY(0) scale(1) !important;
    opacity: 1 !important;
    transform: translateY(0) scale(1) !important;
    opacity: 1 !important;
    -webkit-transition: -webkit-transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s, opacity 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s !important;
    transition: transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s, opacity 1.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s !important;
  }
}
.flash{
  animation: flash 1s infinite;
}

@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.reduced-flash{
  animation: reduced-flash 1s infinite;
}

@keyframes reduced-flash {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.obj-cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
.obj-contain{
    width: 100%;
    height: 100%;
    object-fit: contain;
    font-family: 'object-fit: contain;';
}

.todo{
    display: inline-block;
    background-color: lighten(#ff0000, 20%);
    color: #ffffff;
    border-radius: 10px;
    padding: 6px 10px 10px 10px;
    margin: 5px;
}

.rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.flip-horizontal.flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

/* ==============
Clearing
 ============== */
.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both; }

.force-reveal{
  .reveal{
    transform: translateY(0) scale(1) !important;
    opacity: 1 !important;
  }
}
